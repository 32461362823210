import React from 'react'
import { Typography, Box, Paper } from '@material-ui/core';

function Shipping() {
  return (
      <Box sx={{ flexGrow: 1, p: 5 }}>
        <Paper className='paper-box-shadow' sx={{ p: '1rem' }}>
        <Typography variant='h6' className="mui-heading">Shipping Addresses</Typography>
        </Paper>
    </Box>
  )
}

export default Shipping