import axios from "axios";
import { baseUrl } from "../config/api-config";

export const getSimulateDetail = (token, id) => {
  let url = `${baseUrl}/simulate/${id}`;
  return axios.get(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};
