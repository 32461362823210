import React from "react";
import { useParams } from "react-router-dom";
import { getOpenOrdersDetail } from "../../api/openOrder";
import ControlledTable from "../../components/ControlledTable";
import { openOrderDetailTable as tableDefSecond } from "../../config/grid-columns-defs";
import { openSalesOrderDetailsTable as tableDefFirst } from "../../config/grid-columns-defs";
import { useEffect, useState } from "react";
import { Box, Paper } from "@material-ui/core";
import { Typography } from "@mui/material";
import ControlledSecondTable from "../../components/ControlledSecondTable";
import { useSelector } from "react-redux";
import { handleAxiosError } from "../../config/action-config";
import { useDispatch } from "react-redux";

export default function OpenOrdersDetailList() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const params = useParams();
  const [totalCountForSecondTable, setTotalCountForSecondTable] = useState(0);
  const [rowsForSecondTable, setRowsForSecondTable] = useState([]);
  const [pageNumberForSecondTable, setPageNumberForSecondTable] = useState(0);
  const [totalCountForFirstTable, setTotalCountForFirstTable] = useState(0);
  const [rowsForFirstTable, setRowsForFirstTable] = useState([]);
  const [pageNumberForFirstTable, setPageNumberForFirstTable] = useState(0);


  useEffect(() => {
    refreshTable();
  }, []);

  const getOrderDetailsRows = async (numRows, startRow) => {
    const token = user.token;
    return getOpenOrdersDetail(
      {
        invoice_no: params.invoice_no,
        limit: numRows,
        offset: startRow,
      },
      token
    )
      .then((res) => {
        setTotalCountForSecondTable(
          parseInt(res.data.data.orderDetails.length) === 0
            ? -1
            : res.data.data.orderDetails.length
        );
        return res.data.data.orderDetails;
      })
      .catch((err) => {
        handleAxiosError(err, dispatch);
        return [];
      });
  };
  const getSalesOrderDetailsRows = async (numRows, startRow) => {
    const token = user.token;

    return getOpenOrdersDetail(
      {
        invoice_no: params.invoice_no,
        limit: numRows,
        offset: startRow,
      },
      token
    )
      .then((res) => {
        setTotalCountForFirstTable(
          parseInt(res.data.data.salesOrderDetails.length) === 0
            ? -1
            : res.data.data.salesTotal
        );
        return res.data.data.salesOrderDetails;
      })
      .catch((err) => {
        handleAxiosError(err, dispatch);
        return [];
      });
  };

  const refreshTable = () => {
    const token = user.token;

    getOpenOrdersDetail(
      { invoice_no: params.invoice_no, limit: 10, offset: 0 },
      token
    )
      .then((res) => {
        setTotalCountForFirstTable(
          parseInt(res.data.data.salesTotal) === 0
            ? -1
            : parseInt(res.data.data.salesTotal)
        );
        setRowsForFirstTable(res.data.data.salesOrderDetails);
      })
      .catch((err) => {
        handleAxiosError(err, dispatch);
      });
  };

  return (
    <Box sx={{ flexGrow: 1, p: 5 }}>
      <Paper className="paper-box-shadow" sx={{ p: "1rem" }}>
        <Typography variant="h6" className="mui-heading">
          Sales : Invoice #{params.invoice_no}
        </Typography>
        <ControlledTable
          rows={rowsForFirstTable}
          columnDefs={tableDefFirst}
          getRows={getSalesOrderDetailsRows}
          totalCount={totalCountForFirstTable}
          onChange={setRowsForFirstTable}
          onPageNumberChange={setPageNumberForFirstTable}
          pageSize={10}
          pageNumber={pageNumberForFirstTable}
          pagination={true}
          // getRowNodeId={(data) => data?.name || data?.index}
          hasActionButtons={false}
        />
        <Typography variant="h6" className="mui-heading">
          Open AR Details
        </Typography>
        <ControlledSecondTable
          rows={rowsForSecondTable}
          columnDefs={tableDefSecond}
          getRows={getOrderDetailsRows}
          totalCount={totalCountForSecondTable}
          onChange={setRowsForSecondTable}
          onPageNumberChange={setPageNumberForSecondTable}
          pageSize={10}
          pageNumber={pageNumberForSecondTable}
          pagination={false}
          // getRowNodeId={(data) => data?.name || data?.index}
          hasActionButtons={false}
        />
      </Paper>
    </Box>
  );
}
