import React from "react";
import { Grid, Box, Paper } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { LockOutlined } from "@material-ui/icons";
import { LocationOnOutlined } from "@mui/icons-material";
const useStyles = makeStyles(() => ({
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 16,
    color: "#ce0715",
  },
  desc: {
    fontSize: 14,
    color: "#666666",
    fontWeight: "300`",
  },
  text: {
    p: 5,
    fontSize: 18,
    fontWeight: "bolder",
    color: "black",
  },
  pos: {
    marginBottom: 12,
  },
}));
export default function Account() {
  const classes = useStyles();
  return (
    <div>
      <Box sx={{ flexGrow: 1, p: 5 }}>
        <Typography  variant='h6' className="mui-heading">Your Account</Typography>
        <Box sx={{ m: 2.5 }}></Box>
        <Paper className="paper-box-shadow" sx={{ p: "1rem" }}>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Link to="/profile" style={{ textDecoration: "none" }}>
                <Card className="media-card" sx={{ display: "flex" }}>
                  <Box sx={{ display: "flex" }}>
                    <CardActions>
                      <LockOutlined
                        sx={{ fontSize: 40 }}
                        className="mui-icon lockicon"
                      />
                    </CardActions>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                      >
                        Profile
                      </Typography>
                      <Typography className={classes.desc}>
                        Edit Name, Mobile Number, Default Address, Password
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Link>
            </Grid>
            {/* <Grid item md={4}>
              <Link to="/shipping" style={{ textDecoration: "none" }}>
                <Card className="media-card" sx={{ display: "flex" }}>
                  <Box sx={{ display: "flex" }}>
                    <CardActions>
                      <LocationOnOutlined
                        sx={{ fontSize: 40 }}
                        className="mui-icon locationicon"
                      />
                    </CardActions>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                      >
                        Shipping Addresses
                      </Typography>
                      <Typography className={classes.desc}>
                        Edit or add your shipping address
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Link>
            </Grid> */}
            <Grid item md={4}></Grid>
          </Grid>
        </Paper>
      </Box>
    </div>
  );
}
