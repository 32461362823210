import { useEffect, useState } from "react";
import axios from "axios";

import { buildQuery, baseUrl } from "../../config/api-config";

const limit = 10;
export default function useClosedCustomerSearch (query, pageNumber, token) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        setCustomerList([])
    }, [query])

    useEffect(() => {
        if(!query) { 
            setLoading(false);
            return;
        };
        setLoading(true);
        setError(false);

        let offset = getOffset(pageNumber);
        let qs = buildQuery({ customerName: query, limit, offset });
        let url = `${baseUrl}/openOrderCustomers${qs}`;

        let cancel;
        axios({
            method: "GET",
            url: url,
            cancelToken: new axios.CancelToken(c => cancel = c),
            headers: {
                authorization: `Bearer ${token}`
            }
        }).then(({ data }) => {
            setCustomerList(prev => [...prev, ...data.data.customers]);
            setLoading(false);
            setHasMore(data.data.customers.length > 0);
        }).catch(e => {
            if(axios.isCancel(e)) return;
            setError(true);
        })

        return () => cancel();
    }, [query, pageNumber]);
    
    return { loading, error, customerList, hasMore }
}

function getOffset (page) {
    let max = Math.max((page -1) * limit, 0)
    return max; 
}