export const formatDate = (params) => {
  let localeDate = new Date(params.value).toLocaleDateString();
  let splitted = localeDate.split("/");

  if (parseInt(splitted[0]) < 10) {
    splitted[0] = "0" + splitted[0];
  }

  if (parseInt(splitted[1]) < 10) {
    splitted[1] = "0" + splitted[1];
  }

  localeDate = splitted.join("/");

  return params.value ? localeDate : "";
};

export const formatCurrency = (params) => {
  return params.value ? `₹ ${params.value}` : "N/A";
};

export const formatParentTaskId = (params) => {
  return params.value || "N/A";
};

export const formatCustomDate = (date) => {
  return date ? `${date}`.slice(0, 10) : "N/A";
};

export const formatHour = (params) => {
  return `${params.value ? params.value : 0} Hrs`;
};

export function capitalize(params) {
  if (!params.value) return "";
  let firstChar = params?.value?.slice(0, 1).toUpperCase();
  return firstChar + params?.value?.slice(1);
}
