import { capitalize, formatDate } from "./grid-value-formatters";

import { Link } from "react-router-dom";
import { toUSD } from "./formatters";

export const openOrderTable = [
  {
    headerName: "Invoice#",
    field: "invoice_no",
    resizable: true,
    maxWidth: 120,
    cellRenderer: (params) => {
      return (
        <Link to={`${params?.data?.invoice_no}`} target="_blank">
          {params?.data?.invoice_no}
        </Link>
      );
    },
  },
  {
    headerName: "Status",
    field: "invoice_status",
    resizable: true,
    maxWidth: 70,
  },
  {
    headerName: "Bill To Name",
    field: "bill_to_name",
    resizable: true,
    minWidth: 200,
    width: 250,
  },
  {
    headerName: "Ship To Name",
    field: "ship_to_name",
    resizable: true,
    minWidth: 150,
  },
  {
    headerName: "Item Count",
    field: "item_cnt",
    resizable: true,
    maxWidth: 80,
  },
  {
    headerName: "Ship Date",
    field: "ship_date",
    resizable: true,
    maxWidth: 100,
    valueFormatter: formatDate,
  },
  { headerName: "Ship Via", field: "shipvia", resizable: true, maxWidth: 130 },
  {
    headerName: "Customer PO#",
    field: "cust_po",
    resizable: true,
    maxWidth: 140,
  },
  {
    headerName: "Sales Tax",
    field: "sales_tax",
    resizable: true,
    maxWidth: 110,
    valueFormatter: (params) => toUSD(params.value),
  },
  {
    headerName: "Item Total",
    field: "item_total",
    resizable: true,
    maxWidth: 110,
    valueFormatter: (params) => toUSD(params.value),
  },
];

export const openSalesOrderDetailsTable = [
  {
    headerName: "Product ID",
    field: "product_id",
    resizable: true,
    // cellRenderer: (params) => (
    //   <PageLink params={params} grab="project_id" path="projects/" />
    // ),
  },
  {
    headerName: "Product Description",
    field: "product_desc1",
    resizable: true,
    minWidth: 250,
  },
  {
    headerName: "Ship Date",
    field: "ship_date",
    resizable: true,
    valueFormatter: formatDate,
  },
  {
    headerName: "Quantity",
    field: "quantity",
    resizable: true,
  },
  {
    headerName: "Ext Price",
    field: "ext_price",
    resizable: true,
    valueFormatter: (params) => toUSD(params.value),
  },
  {
    headerName: "Reqd Date",
    field: "reqd_date",
    resizable: true,
    valueFormatter: formatDate,
  },

  {
    headerName: "Order Date",
    field: "order_date",
    resizable: true,
    valueFormatter: formatDate,
  },
  { headerName: "QTY Unit", field: "qty_unit", resizable: true },
];
export const openOrderDetailTable = [
  {
    headerName: "Bill To Name",
    field: "bill_to_name",
    resizable: true,
    minWidth: 270,
    // cellRenderer: (params) => (
    //   <PageLink params={params} grab="project_id" path="projects/" />
    // ),
  },
  {
    headerName: "Ship To Name",
    field: "ship_to_name",
    resizable: true,
    minWidth: 200,
    width: 250,
  },
  {
    headerName: "Invoice Date",
    field: "invoice_date",
    resizable: true,
    valueFormatter: formatDate,
  },
  {
    headerName: "Due Date",
    field: "due_date",
    resizable: true,
    valueFormatter: formatDate,
  },
  {
    headerName: "Balance",
    field: "balance",
    resizable: true,
    valueFormatter: (params) => toUSD(params.value),
  },
  {
    headerName: "Customer PO#",
    field: "customer_po",
    resizable: true,
  },
  { headerName: "Days Past Due", field: "days_past_due", resizable: true },
  {
    headerName: "Original Amount",
    field: "orig_amount",
    resizable: true,
    valueFormatter: (params) => toUSD(params.value),
  },
  {
    headerName: "Paid Amount",
    field: "paid_amount",
    resizable: true,
    valueFormatter: (params) => toUSD(params.value),
  },
];

export const arAgingTable = [
  {
    headerName: "Customer Name",
    field: "bill_to_name",
    resizable: true,
    minWidth: 250,
  },
  {
    headerName: "Current",
    field: "Current",
    resizable: true,
    valueFormatter: (params) => toUSD(params.value),
    width: 150,
  },
  {
    headerName: "0-30 days",
    field: "0-30 days",
    resizable: true,
    valueFormatter: (params) => toUSD(params.value),
    width: 150,
  },
  {
    headerName: "31-60 days",
    field: "31-60 days",
    resizable: true,
    valueFormatter: (params) => toUSD(params.value),
    width: 150,
  },
  {
    headerName: "61-90 days",
    field: "61-90 days",
    resizable: true,
    valueFormatter: (params) => toUSD(params.value),
    width: 150,
  },
  {
    headerName: "91-120 days",
    field: "91-120 days",
    resizable: true,
    valueFormatter: (params) => toUSD(params.value),
    width: 150,
  },
  {
    headerName: "Over 120 days",
    field: "Over 120 days",
    resizable: true,
    valueFormatter: (params) => toUSD(params.value),
    width: 150,
  },
  {
    headerName: "Total",
    field: "total",
    resizable: true,
    valueFormatter: (params) => toUSD(params.value),
    width: 150,
  },
];

export const userTable = [
  {
    headerName: "Role",
    field: "role",
    resizable: true,
    maxWidth: 100,
    width: 100,
    valueFormatter: ({ value }) => value?.toUpperCase(),
  },
  {
    headerName: "Email",
    field: "email",
    resizable: true,
    width: 200,
    minWidth: 120,
  },
  {
    headerName: "Customer Name",
    field: "customer_name",
    resizable: true,
    width: 200,
    minWidth: 120,
  },
  //{ headerName: "First Name", field: "firstname", resizable: true, valueFormatter: capitalize, minWidth: 120, width: 120 },
  //{ headerName: "Last Name", field: "lastname", resizable: true, valueFormatter: capitalize, minWidth: 100, width: 100 },
  {
    headerName: "Contact",
    field: "contact",
    resizable: true,
    minWidth: 100,
    width: 120,
  },
  {
    headerName: "Country",
    field: "country",
    resizable: true,
    valueFormatter: capitalize,
    minWidth: 80,
    width: 120,
  },
  // {
  //   headerName: "Country Code",
  //   field: "country_code",
  //   resizable: true,
  //   maxWidth: 90,
  //   width: 90,
  //   valueFormatter: ({ value }) => `+ ${value}`,
  // },
  {
    headerName: "State",
    field: "state",
    resizable: true,
    minWidth: 90,
    width: 90,
  },
  {
    headerName: "City",
    field: "city",
    resizable: true,
    minWidth: 100,
    width: 100,
  },
  {
    headerName: "Street",
    field: "street",
    resizable: true,
    minWidth: 80,
    width: 80,
  },
  {
    headerName: "Zip/Postal Code",
    field: "zip_code",
    resizable: true,
    minWidth: 60,
    width: 100,
  },
];

export const userDetailTable = [
  {
    headerName: "Bt_Cust_Id",
    field: "bt_cus_id",
    resizable: true,
    maxWidth: 200,
  },
  // {
  //   headerName: "Email",
  //   field: "email",
  //   resizable: true,
  //   minWidth: 300,
  // },
  {
    headerName: "Customer Name",
    field: "customer_name",
    resizable: true,
  },
];
