import axios from "axios";
import { baseUrl, buildQuery } from "../config/api-config";

export const getOpenOrderVariants = (token) => {
  //   let qs = buildQuery(query);
  let url = `${baseUrl}/openOrderVariants`;
  return axios.get(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};
