import "./App.css";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Routes,
  Route,
  Link,
  Outlet,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { getUser } from "./actions/auth";
import AlertBox from "./components/AlertBox";
import Layout from "./components/Layout";
import ARAging from "./pages/ar_aging/arAging";
import ClosedOrdersDataList from "./pages/closed_orders/closedOrderDataList";
import Kpi from "./pages/kpi/kpi";
import Account from "./pages/account/account";
import Login from "./pages/login/login";
import OpenOrdersDetailList from "./pages/open_orders/openOrderDetail";
import OpenOrdersDataList from "./pages/open_orders/openOrdersDataList";
import UserList from "./pages/users/UserList";
import Profile from "./pages/account/Profile/profile";
import ShippingAddresses from "./pages/account/ShippingAddresses/shipping";
import ForgetPassword from "./pages/reset_forget_password/forgetPassword";
import ResetPassword from "./pages/reset_forget_password/resetPassword";
import UpdatePassword from "./pages/reset_forget_password/updatePassword";
import ClosedOrdersDetailList from "./pages/closed_orders/closedOrderDetail";
import DocData from "./pages/doc_viewer/doc";
import AddUser from "./pages/users/AddUserPage";
import EnableMfa from "./pages/mfa/enableMfa";
import LoginMfa from "./pages/mfa/loginMfa";
import UpdateUser from "./pages/users/UpdateUserDialog";
import SimulateCustomerList from "./pages/simulate/simulate_customer";

function App() {
  const user = useSelector((state) => state.user);

  return (
    <Routes>
      <Route
        path="login"
        element={
          <NoAuth>
            <>
              <Login />
              <AlertBox />
            </>
          </NoAuth>
        }
      />

      <Route
        path="verify-mfa"
        element={
          <NoAuth>
            <LoginMfa />
          </NoAuth>
        }
      />

      <Route path="/" element={<RequireAuth />}>
        <Route
          path="/"
          element={
            <Layout>
              {/customer/i.test(user.role) ? (
                <Navigate to="/openOrders" />
              ) : (
                <Kpi />
              )}
            </Layout>
          }
        />

        <Route
          path="openOrders"
          element={
            <Layout>
              <OpenOrdersDataList />
            </Layout>
          }
        />

        <Route
          path="closedOrders"
          element={
            <Layout>
              <ClosedOrdersDataList />
            </Layout>
          }
        />

        <Route
          path="ARAging"
          element={
            <Layout>
              <ARAging />
            </Layout>
          }
        />

        <Route
          path="account"
          element={
            <Layout>
              <Account />
            </Layout>
          }
        />

        <Route
          path="openOrders/:invoice_no"
          element={
            <Layout>
              <OpenOrdersDetailList />
            </Layout>
          }
        />

        <Route
          path="closedOrders/:invoice_no"
          element={
            <Layout>
              <ClosedOrdersDetailList />
            </Layout>
          }
        />

        <Route
          path="users"
          element={
            <Layout>
              <UserList />
            </Layout>
          }
        />
        <Route
          path="users/:id"
          element={
            <>
              <Layout>
                <UpdateUser />
              </Layout>
              <AlertBox />
            </>
          }
        />

        <Route
          path="users/add"
          element={
            <Layout>
              <AddUser />
            </Layout>
          }
        />
        <Route
          path="simulate"
          element={
            <Layout>
              <SimulateCustomerList />
            </Layout>
          }
        />

        <Route
          path="profile"
          element={
            <Layout>
              <Profile />
            </Layout>
          }
        />

        <Route
          path="enable-mfa"
          element={
            <Layout>
              <EnableMfa />
            </Layout>
          }
        />

        <Route
          path="shipping"
          element={
            <Layout>
              <ShippingAddresses />
            </Layout>
          }
        />

        <Route
          path="updatePassword"
          element={
            <>
              <Layout>
                <UpdatePassword />
              </Layout>
              <AlertBox />
            </>
          }
        />

        <Route
          path="docViewer"
          element={
            <>
              <Layout>
                <DocData />
              </Layout>
              <AlertBox />
            </>
          }
        />
      </Route>

      <Route
        path="resetPassword"
        element={
          <>
            <ResetPassword />
            <AlertBox />
          </>
        }
      />

      <Route
        path="forgetPassword"
        element={
          <>
            <ForgetPassword />
            <AlertBox />
          </>
        }
      />

      <Route path="*" element={<Nothing />}></Route>
    </Routes>
  );
}

export default App;

function RequireAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isLoggedIn } = useSelector((state) => state.user);
  const [suspense, setSuspense] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && !isLoggedIn) {
      // console.log("Token but not logged in");
      dispatch(getUser(token));
      setSuspense(true);
    } else if (isLoggedIn) {
      // console.log("Logged in");
      setSuspense(false);
      navigate(pathname);
    } else {
      // console.log("No token no logged in")
      navigate("/login", { replace: true });
    }
  }, [pathname, isLoggedIn]);

  return suspense ? <Loading /> : <Outlet />;
}

function NoAuth({ children }) {
  const token = localStorage.getItem("token");
  const { isLoggedIn } = useSelector((state) => state.user);

  return token || isLoggedIn ? <Navigate to="/" /> : children;
}

function Nothing() {
  return (
    <div className="page d-flex">
      <div className="m-auto text-center">
        <Typography variant="h3" fontWeight="600">
          Nothing Here !!!
        </Typography>
        <br />
        <Link to="/">
          <Button variant="contained">Back To Home</Button>
        </Link>
      </div>
    </div>
  );
}

function Loading() {
  return (
    <div className="page d-flex">
      <div className="m-auto text-center ">
        <Typography variant="h3" fontWeight="600" className="mui-center">
          Logging In ...
        </Typography>
        <br />
        <CircularProgress className="mui-center" />
      </div>
    </div>
  );
}
