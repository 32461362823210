import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch } from "react-redux";

export function ActionCell({ params }) {
  const dispatch = useDispatch();
  return (
    <>
      <button
        className="btn btn-warning py-1 px-2"
        onClick={() => {
          dispatch({ type: "EDIT", data: params.data });
        }}
      >
        <EditIcon fontSize="inherit" />
      </button>
      <button
        className="btn btn-danger py-1 px-2 mx-1"
        onClick={() => {
          dispatch({ type: "DELETE", data: params.data });
        }}
      >
        <DeleteIcon fontSize="inherit" />
      </button>
      <button
        className="btn btn-info py-1 px-2"
        onClick={() => {
          dispatch({ type: "VIEW", data: params.data });
        }}
      >
        <VisibilityIcon fontSize="inherit" />
      </button>
    </>
  );
}
