import axios from "axios";
import { baseUrl } from "../config/api-config";

const base = `${baseUrl}/auth`;

export function fetchTokenUser(token) {
  return axios.get(base + "/user", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function emailLogin(email, password) {
  return axios.post(base + "/login", { email, password });
}

export function forgetPassword(email) {
  return axios.post(base + "/forget-password", { email });
}

export function resetPassword(email, otp, newPassword, confirmNewPassword) {
  return axios.post(base + "/reset-password", {
    email,
    otp,
    newPassword,
    confirmNewPassword,
  });
}
export function updatePassword(oldPassword, newPassword, token) {
  return axios.patch(
    baseUrl + "/user-password",
    {
      oldPassword,
      newPassword,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function enableMfa (token) {
  return axios.post(`${base}/sign-up-mfa`, {}, {
    headers: {
      authorization: `Bearer ${token}`
    }
  })
}

export function validateMfa (otp, token) {
  return axios.post(`${base}/validate-mfa`, { otp }, {
    headers: {
      authorization: `Bearer ${token}`
    }
  })
}

export function validateMfaLogin (email, otp) {
  return axios.post(`${base}/validate-mfa-login`, { otp, email })
}