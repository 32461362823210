import React, { useState } from "react";
import { addUsers } from "../../api/User";
// import { Paper } from "@material-ui/core";
import {
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import useCustomerSearch from "./useCustomerSearch";
import { CountryCodes, role } from "../../CountryPhoneCodes";
import { handleAxiosError } from "../../config/action-config";
import { Link, useNavigate } from "react-router-dom";

const selectedCustomer = { id: "", name: "" };
export default function AddUser() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    role: "",
    customer_name: "",
    country_code: "",
    contact: "",
    city: "",
    state: "",
    zip_code: "",
    email: "",
    country: "",
    street: "",
  });

  const onInputChange = ({ target }) => {
    setData((prev) => ({ ...prev, [target.name]: target.value }));
  };

  // const [pageNumber, setPageNumber] = useState(0);
  // const selectedCustomer = { id: "", name: "" };
  // const [search, setSearch] = useState("");

  // const { loading, customerList, hasMore } = useCustomerSearch(search, pageNumber, user.token);

  // const observer = useRef();
  // const lastCustomerElementRef = useCallback(node => {
  //     if (loading) return;
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver(entries => {
  //         if (entries[0].isIntersecting && hasMore) {
  //             setPageNumber(prev => { return prev + 1 });
  //         }
  //     });
  //     if (node) observer.current.observe(node);
  // }, [loading, hasMore])

  // const handleSearchValueChange = (e) => {
  //     setPageNumber(1);
  //     setSearch(e.target.value.toUpperCase());
  // }

  const onFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await addUsers(user.token, {
        ...data,
        cust_id: selectedCustomer.id.toString(),
      });
      alert(res.data.msg);
      navigate("/users");
    } catch (error) {
      handleAxiosError(error, dispatch);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 5 }}>
      <Typography variant="h6" className="mui-heading">
        Add User
      </Typography>
      <Box component="form" onSubmit={onFormSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="First Name"
              name="firstname"
              type="text"
              fullWidth
              required
              size="small"
              onChange={onInputChange}
              value={data.firstname}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Last Name"
              name="lastname"
              type="text"
              fullWidth
              required
              size="small"
              onChange={onInputChange}
              value={data.lastname}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Email"
              name="email"
              type="text"
              fullWidth
              size="small"
              required
              onChange={onInputChange}
              value={data.email}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Role"
              name="role"
              type="text"
              variant="outlined"
              fullWidth
              size="small"
              required
              select
              value={data.role}
              onChange={onInputChange}
            >
              <MenuItem value="">Select</MenuItem>

              {role
                .filter((r) => {
                  return user.role === "superadmin"
                    ? r.Type === "customer"
                    : r.Type;
                })
                .map((data, key) => (
                  <MenuItem
                    key={key}
                    value={data.Type}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {data.Type}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          {data.role === "customer" ? (
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Customer Name"
                name="customer_name"
                type="text"
                fullWidth
                size="small"
                onChange={onInputChange}
                value={data.customer_name}
              />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Country"
              name="country"
              type="text"
              fullWidth
              select
              size="small"
              onChange={({ target }) => {
                let selected = CountryCodes.find(
                  (c) => c.country === target.value
                );
                setData((prev) => ({
                  ...prev,
                  country: selected.country,
                  country_code: selected.code,
                }));
              }}
              value={data.country}
            >
              <MenuItem value="">Select</MenuItem>
              {CountryCodes.map((c, i) => (
                <MenuItem key={i} value={c.country}>
                  {c.country}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Country Code"
              name="country_code"
              type="text"
              variant="outlined"
              fullWidth
              size="small"
              select
              value={data.country_code}
              onChange={({ target }) => {
                let selected = CountryCodes.find(
                  (c) => c.code === target.value
                );
                setData((prev) => ({
                  ...prev,
                  country: selected.country,
                  country_code: selected.code,
                }));
              }}
            >
              <MenuItem value="">Select</MenuItem>
              {CountryCodes.map((data, key) => (
                <MenuItem key={key} value={data.code}>
                  +{data.code}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Contact"
              name="contact"
              type="tel"
              size="small"
              inputProps={{
                maxLength: 14,
              }}
              fullWidth
              onChange={onInputChange}
              value={data.contact}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Street"
              name="street"
              size="small"
              type="text"
              fullWidth
              onChange={onInputChange}
              value={data.street}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="City"
              name="city"
              size="small"
              type="text"
              fullWidth
              onChange={onInputChange}
              value={data.city}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="State"
              name="state"
              type="text"
              size="small"
              fullWidth
              onChange={onInputChange}
              value={data.state}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Zip/Postal Code"
              name="zip_code"
              type="text"
              inputProps={{
                maxLength: 7,
              }}
              size="small"
              fullWidth
              onChange={onInputChange}
              value={data.zip_code}
            />
          </Grid>
          {/* {
                        Boolean(selectedCustomer.id) ? <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                label="Bill To Customer"
                                name="bill_to_customer"
                                type="text"
                                size="small"
                                fullWidth
                                onChange={() => { setSelectedCustomer({ id: "", name: "" }) }}
                                value={`${selectedCustomer.id} ${selectedCustomer.name}`}
                            />
                        </Grid>
                            :
                            <>
                                {
                                    /customer/i.test(data.role) ? <Grid item xs={12} md={6} lg={4} id="search-area">
                                        <TextField value={search} onChange={handleSearchValueChange} variant="outlined"
                                            fullWidth size="small" label="Search Customer"
                                            onFocus={() => { document.getElementById('drop-box').classList.add('block') }}
                                            onBlur={() => { document.getElementById('drop-box').classList.remove('block') }}
                                            InputProps={{
                                                endAdornment: (
                                                    loading ? <InputAdornment position="end"><CircularProgress size="1.5rem" /></InputAdornment> : <></>
                                                )
                                            }}
                                        />
                                        <Paper style={{ position: "relative" }}>

                                            <Box maxHeight={200}
                                                minHeight={Math.min(customerList.length * 30, 200)}
                                                id="drop-box"
                                                style={{ overflowY: "scroll" }}
                                                zIndex={4}
                                                sx={{ background: "#fff", boxShadow: "2px 2px 5px gray" }}
                                                position="absolute"
                                                width="100%"
                                            >
                                                {
                                                    customerList.map((c, idx) => {
                                                        if (customerList.length === idx + 1) {
                                                            return <div key={idx} ref={lastCustomerElementRef} className='c-list-item'
                                                                onClick={() => { setSelectedCustomer({ id: c.cust_id, name: c.customer_name }) }}>
                                                                {c.cust_id} - {c.customer_name}
                                                            </div>
                                                        }
                                                        return <div key={idx} className='c-list-item'
                                                            onClick={() => { setSelectedCustomer({ id: c.cust_id, name: c.customer_name }) }}>
                                                            {c.cust_id} - {c.customer_name}
                                                        </div>
                                                    })
                                                }
                                            </Box>
                                        </Paper>
                                    </Grid> : <></>
                                }
                            </>
                    } */}
          <Grid item xs={12} md={12} xl={12}>
            <Box
              display="flex"
              pt={1}
              pr={1}
              justifyContent="flex-end"
              columnGap={1}
            >
              <Link to="/users" style={{ textDecoration: "none" }}>
                <Button
                  className="red-button submit"
                  size="small"
                  type="submit"
                >
                  Cancel
                </Button>
              </Link>
              <Button className="red-button submit" size="small" type="submit">
                Create
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
