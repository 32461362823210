import { alertActions } from "../config/action-config.js";

const initial = { open: false, isError: false, message: "" };

const alertReducer = (state = initial, action) => {
  const { payload, type } = action;
  switch (type) {
    case alertActions.SHOW:
      return { ...payload, open: true, isError: false };
    case alertActions.ERROR:
      return { ...payload, open: true, isError: true };
    case alertActions.HIDE:
      return { open: false, isError: false };
    default:
      return state;
  }
};

export default alertReducer;
