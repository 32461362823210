import { useState, useEffect, useMemo } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import { Button, CircularProgress } from "@mui/material";
import { getUsers } from "../../api/User";
import { Box } from "@mui/system";

export default function SimulateUserTable({
  columns,
  perPage = 10,
  token,
  refreshCount,
  company_name,
  email,
}) {
  const [gridApi, setGridApi] = useState(null);
  const [noTableMsg, setNoTableMsg] = useState(".");
  const [loading, setLoading] = useState(false);

  const [availableToApprove, setAvailableToApprove] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const isRowSelectable = useMemo(() => {
    return (params) => {
      return params.data && !params.data?.is_approved;
    };
  }, []);

  const onRowSelected = (params) => {
    if (/^\w+@\w+\.\w+/.test(params.data.email)) {
      setAvailableToApprove(Boolean(gridApi.getSelectedRows().length));
    } else {
      alert("Please update the email to a valid one.");
    }
  };

  useEffect(() => {
    if (gridApi) {
      setLoading(true);
      setApprovalLoading(false);
      setAvailableToApprove(false);
      const dataSource = {
        getRows: (params) => {
          getUsers(
            {
              limit: perPage,
              offset: params.endRow - perPage,
              company_name,
              email,
              sortBy: "role DESC",
            },
            token
          )
            .then(({ data }) => {
              setNoTableMsg("");
              setLoading(false);
              params.successCallback(
                data.data.users,
                parseInt(data.data.total)
              );
            })
            .catch((err) => {
              setNoTableMsg("Error Occured While Loading Data");
              setLoading(false);
              params.successCallback([], 0);
            });
        },
      };
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, perPage, refreshCount, company_name, email]);

  return (
    <>
      <h4>{noTableMsg}</h4>
      <br />
      <Box display="flex" justifyContent="space-between" pb={1}>
        {loading ? <CircularProgress /> : <></>}
      </Box>
      <div
        aria-live="polite"
        aria-busy={false}
        className="ag-theme-material"
        style={{
          height: "600px",
          visibility: Boolean(noTableMsg || loading) ? "hidden" : "visible",
        }}
      >
        <AgGridReact
          rowHeight={40}
          rowSelection={"multiple"}
          suppressRowClickSelection={true}
          isRowSelectable={isRowSelectable}
          columnDefs={columns}
          pagination={true}
          rowModelType="infinite"
          paginationPageSize={perPage}
          cacheBlockSize={perPage}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          onRowSelected={onRowSelected}
        />
      </div>
    </>
  );
}
