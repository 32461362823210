import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, Stack, Typography } from "@mui/material";

const emptyUserDetail = {
  id: "",
  bt_cus_id: "",
  customer_name: "",
  email: "",
};

export default function AddUpdateUserDetails({
  open,
  setOpen,
  buttonType,
  userDetailById,
  setUserDetailById,
  handleSave,
  handleDelete,
}) {
  const onInputChange = (e) => {
    setUserDetailById((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setUserDetailById(emptyUserDetail);
        }}
        fullWidth={true}
      >
        <DialogTitle>{buttonType + " Mapping Details"}</DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Bt_Cust_Id"
                name="bt_cus_id"
                size="small"
                type="text"
                fullWidth
                onChange={onInputChange}
                value={userDetailById.bt_cus_id}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Customer Name"
                name="customer_name"
                size="small"
                type="text"
                fullWidth
                onChange={onInputChange}
                value={userDetailById.customer_name}
              />
            </Grid>

            {/* <Grid item xs={12} md={6}>
              <TextField
                label="Email Id"
                name="email"
                size="small"
                type="text"
                disabled
                fullWidth
                onChange={onInputChange}
                value={userDetailById.email}
              />
            </Grid> */}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Stack
            width={buttonType === "Add" ? 200 : 250}
            pt={2}
            direction="row"
            justifyContent="space-evenly"
          >
            <Button
              className="cancel-button"
              onClick={() => {
                setOpen(false);
                setUserDetailById(emptyUserDetail);
              }}
            >
              Cancel
            </Button>
            {buttonType === "Update" ? (
              <Button
                className="red-button submit"
                size="small"
                onClick={() => {
                  handleSave(userDetailById);
                }}
              >
                Update
              </Button>
            ) : (
              <></>
            )}
            {buttonType === "Update" ? (
              <Button
                className="red-button submit"
                size="small"
                onClick={() => {
                  handleDelete(userDetailById);
                }}
              >
                Delete
              </Button>
            ) : (
              <></>
            )}
            {buttonType === "Add" ? (
              <Button
                className="red-button submit"
                size="small"
                onClick={() => {
                  handleSave(userDetailById);
                }}
              >
                Submit
              </Button>
            ) : (
              <></>
            )}
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
}
