import { useState, useEffect } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import { getClosedOrders } from "../../api/closedOrder";
import { CircularProgress } from "@mui/material";

export default function ClosedCustomerOrderTable({
  //   setVariants,
  columns,
  perPage = 10,
  selectedCustomer,
  token,
  invoice,
  fromDate,
  toDate,
}) {
  const [gridApi, setGridApi] = useState(null);
  const [noTableMsg, setNoTableMsg] = useState(".");
  const [loading, setLoading] = useState(false);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridApi && selectedCustomer.id) {
      setLoading(true);
      const dataSource = {
        getRows: (params) => {
          getClosedOrders(
            {
              limit: perPage,
              offset: params.endRow - perPage,
              customerId: selectedCustomer.id,
              invoice_no: invoice,
              ship_date_from: fromDate
                ? convertToUTCDateString(createDateAsUTC(fromDate))
                : "",
              ship_date_to: toDate
                ? convertToUTCDateString(createDateAsUTC(toDate))
                : "",
            },
            token
          )
            .then(({ data }) => {
              setNoTableMsg("");
              setLoading(false);
              //   setVariants(data.data.variants);
              params.successCallback(
                data.data.records,
                parseInt(data.data.total)
              );
            })
            .catch((err) => {
              setNoTableMsg("Error Occured While Loading Data");
              setLoading(false);
              params.successCallback([], 0);
            });
        },
      };
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, perPage, selectedCustomer.id, invoice, fromDate, toDate]);

  return (
    <>
      <h4>{noTableMsg}</h4>
      <br />
      {loading ? <CircularProgress /> : <></>}
      <div
        aria-live="polite"
        aria-busy={false}
        className="ag-theme-material"
        style={{
          height: "600px",
          visibility: Boolean(noTableMsg || loading) ? "hidden" : "visible",
        }}
      >
        <AgGridReact
          rowHeight={30}
          columnDefs={columns}
          pagination={true}
          rowModelType="infinite"
          paginationPageSize={perPage}
          cacheBlockSize={perPage}
          onGridReady={onGridReady}
          domLayout="autoHeight"
        />
      </div>
    </>
  );
}
function convertToUTCDateString(dateString) {
  // Create a Date object from the input date string
  const dateObject = new Date(dateString);

  // Get the UTC date string in ISO 8601 format
  const utcISOString = dateObject.toISOString();

  // Return the date string in UTC timezone
  return utcISOString;
}
function createDateAsUTC(date) {
  return date
    ? new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds()
        )
      )
    : "";
}
