import { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateMfaLogin } from "../../api/auth";

import { Alert, Box, Button, CircularProgress, Grid, Paper, TextField } from "@mui/material";
import { useState } from "react";
import { getUser } from "../../actions/auth";
import { useNavigate } from "react-router-dom";

import BackgroundImage from "../../images/fabrication.jpg";

const email = sessionStorage.getItem('email');
const initialState = { error: "", loading: false, success: false }
function reducer(state, action) {
    switch (action.type) {
        case "SUCCESS": return { ...initialState, loading: false, success: true };
        case "ERROR": return { error: action.payload, loading: false, success: true };
        default: return state;
    }
}
export default function LoginMfa() {

    const [localState, dispatch] = useReducer(reducer, initialState);
    const globalDispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);

    const [mfaOtp, setMfaOtp] = useState("");

    useEffect(() => {
        if(user.token) {
            navigate("/profile", { replace: true })
        }
    }, [user.token])

    const onFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const { data } = await validateMfaLogin(email, mfaOtp);
            if (data.status) {
                globalDispatch(getUser(data.data.token));
            } else {
                dispatch({ type: "ERROR", payload: "Wrong OTP" })
            }
        } catch (error) {
            console.log(error);
            alert(error.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Box height="100vh" style={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: 'cover' }}>
            <Box component='form' display="flex" justifyContent="center" alignItems="center" onSubmit={onFormSubmit}>
                <Paper sx={{ p: 1, width: 400 }} elevation={3}>
                    <Grid container rowGap={2}>
                        <Grid item xs={12} lg={12}>
                            { loading ? <CircularProgress /> : <></> }
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            {
                                Boolean(localState.error) ?
                                    <Alert severity="error">{localState.error}</Alert> :
                                    <Alert severity="success">Enter the OTP sent to {email}</Alert>
                            }
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextField size="small" value={mfaOtp} onChange={e => setMfaOtp(e.target.value)}
                                variant="outlined" fullWidth required label="OTP" autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Button type="submit" variant="contained" size="small" fullWidth className="pr-btn">Submit</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Box>
    )
}