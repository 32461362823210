import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  makeStyles,
  useTheme,
  useMediaQuery,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponent from "./Drawer";
import { ArrowDropDown } from "@mui/icons-material";
//import { Button } from "@mui/material";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { logout } from "../actions/auth";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Logo from "../../src/images/logo-ACT.png";
import Footer from "./Footer.jsx";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    // marginBottom: theme.spacing(1.5),
    display: "flex",
    marginLeft: "auto",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "14px",
    // "&:hover": {
    // color: "white",
    //  borderBottom: "1px solid white",
    //},/
  },

  logout: {
    textDecoration: "none",
    color: "white",
    fontSize: "18px",
    marginLeft: theme.spacing(5),
  },
}));

export default function Layout({ children }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const handleLogout = () => {
    dispatch(logout());
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <div>
      <AppBar position="static" style={{ backgroundColor: "#ce0715" }}>
        <CssBaseline />
        <Toolbar>
          <Link to="/">
            <Box
              component="img"
              sx={{
                //marginBottom: 10,
                height: 40,
                width: "auto",
              }}
              alt="The house from the offer."
              src={Logo}
            />
          </Link>

          {isMobile ? (
            <DrawerComponent />
          ) : (
            <div className={classes.navlinks}>
              <ul>
                {/^(superadmin|admin|enterpriseadmin)$/i.test(user.role) ? (
                  <>
                    <li className={splitLocation[1] === "" ? "active" : ""}>
                      <Link to="/" className="linkkpi">
                        KPI
                      </Link>
                    </li>
                    <li
                      className={splitLocation[1] === "ARAging" ? "active" : ""}
                    >
                      <Link to="/ARAging" className="link">
                        AR Aging
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                <li
                  className={splitLocation[1] === "openOrders" ? "active" : ""}
                >
                  <Link to="/openOrders" className="link">
                    Open Orders
                  </Link>
                </li>
                <li
                  className={
                    splitLocation[1] === "closedOrders" ? "active" : ""
                  }
                >
                  <Link to="/closedOrders" className="link">
                    Closed Orders
                  </Link>
                </li>

                {/^superadmin|enterpriseadmin/i.test(user.role) ? (
                  <li className={splitLocation[1] === "users" ? "active" : ""}>
                    <Link to="/users" className="link">
                      Users
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {/^superadmin|enterpriseadmin/i.test(user.role) ? (
                  <li
                    className={splitLocation[1] === "simulate" ? "active" : ""}
                  >
                    <Link to="/simulate" className="link">
                      Simulator
                    </Link>
                  </li>
                ) : (
                  <></>
                )}

                <li className={splitLocation[1] === "account" ? "active" : ""}>
                  <Link to="/account" className="link">
                    Account
                  </Link>
                </li>
              </ul>
              <Button
                className="dropdown-button"
                id="fade-button"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                startIcon={<PersonOutlineIcon />}
                endIcon={<ArrowDropDown />}
              >
                {user.email}
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
              {/*<Button onClick={handleLogout}>
                <LogoutOutlined color="inherit" />
              </Button>*/}
            </div>
          )}
        </Toolbar>
      </AppBar>
      <main className={classes.main}>{children}</main>
      <Footer></Footer>
    </div>
  );
}
