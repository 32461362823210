import React, { useEffect } from "react";
import { userTable as tableDef } from "../../config/grid-columns-defs";
import { useState } from "react";
import { updateUsers, deleteUsers, approveCustomers } from "../../api/User";
import { getUser } from "../../actions/auth";
import { IconButton, Paper } from "@material-ui/core";
import {
  Button,
  Box,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { handleAxiosError } from "../../config/action-config";
import { useDispatch } from "react-redux";
import UpdateDialog from "./UpdateUserDialog";
import { Link, useNavigate } from "react-router-dom";
import UserTable from "./UserTable";
import { showAlert } from "../../actions/alert";
import { Stack } from "@mui/system";

const emptyUpdateFormData = {
  id: "",
  firstname: "",
  lastname: "",
  email: "",
  role: "",
  country: "",
  country_code: "",
  contact: "",
  street: "",
  city: "",
  state: "",
  zip_code: "",
};

export default function UserList() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const dialogAction = useSelector((state) => state.dialog);
  const navigate = useNavigate();
  const [refreshCount, setRefreshCount] = useState(0);
  const refreshTable = () => {
    setRefreshCount((prev) => prev + 1);
  };

  const [columnDef, setColumnDef] = useState(tableDef);
  const [updateFormData, setUpdateFormData] = useState(emptyUpdateFormData);

  useEffect(() => {
    if (!columnDef.some((o) => o.headerName === "Action")) {
      setColumnDef((prev) => [
        {
          headerName: "Action",
          maxWidth: 120,
          cellRenderer: (params) => (
            <Box display="flex" alignItems="center" sx={{ width: "100px" }}>
              <IconButton
                onClick={() => {
                  console.log("view by id");
                  dispatch({ type: "VIEW", data: params.data });
                  // setUpdateFormData(params.data);
                }}
              >
                <Edit color="primary" />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleDelete(params.data.id);
                }}
              >
                <Delete color="primary" />
              </IconButton>
            </Box>
          ),
        },
        // {
        //   headerName: "Status",
        //   field: "is_approved",
        //   maxWidth: 110,
        //   cellRenderer: ApprovalButton
        // },
        {
          headerName: "Approve",
          field: "is_approved",
          maxWidth: 80,
          checkboxSelection: ({ value }) => !value,
          showDisabledCheckboxes: false,
          valueFormatter: ({ value }) => (value ? "✔" : ""),
        },
        ...prev,
      ]);
    }
  }, [columnDef]);
  useEffect(() => {
    if (!dialogAction.type) return;
    switch (dialogAction.type) {
      case "VIEW":
        dispatch({ type: "" });
        navigate(`${dialogAction.data.id}`);
        break;
      default:
        break;
    }
  }, [dialogAction]);

  //deleting a user
  const handleDelete = async (id) => {
    const token = user.token;

    const confirm = window.confirm(
      `Are you sure, you want to delete this user:  ${id}`
    );
    if (!confirm) return;
    try {
      await deleteUsers(token, id);
      refreshTable();
      dispatch(showAlert("Successfully Deleted"));
    } catch (error) {
      handleAxiosError(error, dispatch);
    }
  };

  // const handleSave = async (allData) => {
  //   try {
  //     const { data } = await updateUsers(
  //       user.token,
  //       getUpdatableUserData(allData),
  //       allData.id
  //     );
  //     refreshTable();
  //     dispatch(showAlert(data.msg));
  //     if (allData.id === user.id) {
  //       dispatch(getUser(user.token));
  //     }
  //     setUpdateFormData(emptyUpdateFormData);
  //   } catch (error) {
  //     setUpdateFormData(emptyUpdateFormData);
  //     handleAxiosError(error, dispatch);
  //   }
  // };

  const onCustomerApprove = async (ids) => {
    try {
      const { data } = await approveCustomers(user.token, ids);
      dispatch(showAlert(data.msg));
    } catch (error) {
      dispatch(showAlert(error.message));
    } finally {
      refreshTable();
    }
  };
  const [searchEmail, setSearchEmail] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const onSearchSubmit = (e) => {
    e.preventDefault();
    setCompanyName(searchQuery);
    setEmail(searchEmail);
  };
  const onSearchEmailSubmit = (e) => {
    e.preventDefault();
    setEmail(searchEmail);
  };
  const onSearchChange = (e) => {
    setSearchQuery(e.target.value.toUpperCase());
    if (!e.target.value) setCompanyName("");
  };
  const onSearchEmail = (e) => {
    setSearchEmail(e.target.value);
    if (!e.target.value) setEmail("");
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1, p: 5 }}>
        <Paper className="paper-box-shadow" sx={{ p: "1rem" }}>
          <Box>
            <Link to="/users/add" style={{ textDecoration: "none" }}>
              <Button className="red-button add-user" variant="contained">
                Add Users
              </Button>
            </Link>
          </Box>

          <Box display="flex" pt={4} justifyContent="space-between">
            <Typography fontWeight={600} variant="h5">
              Users
            </Typography>
            <Box component="form" onSubmit={onSearchSubmit} width={300}>
              <TextField
                type="search"
                fullWidth
                size="small"
                label="Search by Customer Name"
                helperText={searchQuery ? "Press Enter to Search" : ""}
                value={searchQuery}
                onChange={onSearchChange}
              />
            </Box>
          </Box>
          <Stack pt={2} direction="row" justifyContent="end">
            <Box component="form" onSubmit={onSearchEmailSubmit} width={300}>
              <TextField
                type="search"
                fullWidth
                size="small"
                label="Search by Email "
                helperText={searchEmail ? "Press Enter to Search" : ""}
                value={searchEmail}
                onChange={onSearchEmail}
              />
            </Box>
          </Stack>

          {/* <UpdateDialog
            isOpen={Boolean(updateFormData.id)}
            handleClose={() => { setUpdateFormData(emptyUpdateFormData) }}
            data={updateFormData}
            setData={setUpdateFormData}
            handleSave={handleSave}
          /> */}

          <UserTable
            email={email}
            company_name={companyName}
            columns={columnDef}
            token={user.token}
            refreshCount={refreshCount}
            onApprove={onCustomerApprove}
          />
        </Paper>
      </Box>
    </div>
  );
}

function ApprovalButton(params) {
  const user = useSelector((state) => state.user);
  const [inProgress, setInProgress] = useState(false);
  const [approved, setApproved] = useState(Boolean(params.value));

  if (Boolean(params.value))
    return (
      <Button
        disabled={true}
        color="secondary"
        size="small"
        variant="contained"
      >
        Approved
      </Button>
    );

  const onButtonClick = async () => {
    if (inProgress) return;
    setInProgress(true);
    try {
      await approveCustomers(user.token, [params.data.id]);
      setApproved(true);
    } catch (error) {
      alert(error.message);
    } finally {
      setInProgress(false);
    }
  };

  return approved ? (
    <Button disabled={true} color="secondary" size="small" variant="contained">
      Approved
    </Button>
  ) : inProgress ? (
    <CircularProgress size="1.5rem" />
  ) : (
    <Button size="small" variant="contained" onClick={onButtonClick}>
      Approve
    </Button>
  );
}

function getUpdatableUserData(data) {
  return {
    firstname: data.firstname,
    lastname: data.lastname,
    email: data.email,
    role: data.role,
    country: data.country,
    country_code: data.country_code,
    contact: data.contact,
    street: data.street,
    city: data.city,
    state: data.state,
    zip_code: data.zip_code,
  };
}
