import axios from "axios";

import { buildQuery, downloadUrl } from "../config/api-config";

export const getPdfViewer = (query, token) => {

  let qs = buildQuery(query);

  let url = `${downloadUrl}/ACTPIPE/api/files/download${qs}`;

  return axios.get(url, {

    auth: {

      username: "actpipe@vastedge.com",

      password: "Op202jgsph11!!",

    },

    responseType: "blob",

  });

};