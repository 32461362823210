export function toUSD(number) {
  return number
    ? new Intl.NumberFormat("en-us", {
        style: "currency",
        currency: "USD",
      }).format(number)
    : "";
}

export function toUSCompact(number) {
  return new Intl.NumberFormat("en", { notation: "compact" }).format(number);
}
