import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { CountryCodes } from "../../CountryPhoneCodes";
import {
  addMappingUserDetail,
  deletMappingeUserDetail,
  getUserById,
  updateMappingUserDetail,
  updateUsers,
} from "../../api/User";
import { useParams } from "react-router-dom";
import { handleAxiosError } from "../../config/action-config";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import { showAlert } from "../../actions/alert";
import { getUser } from "../../actions/auth";
import { userDetailTable as tableDef } from "../../config/grid-columns-defs";
import { AgGridReact } from "ag-grid-react";
import AddUpdateUserDetails from "./addUserDetailsDialog";
import { Delete, Edit } from "@mui/icons-material";
const emptyUpdateFormData = {
  id: "",
  firstname: "",
  lastname: "",
  customer_name: "",
  email: "",
  role: "",
  country: "",
  country_code: "",
  contact: "",
  street: "",
  city: "",
  state: "",
  zip_code: "",
};
const emptyUserDetail = {
  id: "",
  bt_cus_id: "",
  customer_name: "",
  email: "",
};

export default function UpdateUser() {
  const [data, setData] = useState(emptyUpdateFormData);
  const [mappingDetails, setMappingDetails] = useState([]);
  const user = useSelector((state) => state.user);
  const [updateFormData, setUpdateFormData] = useState(emptyUpdateFormData);
  const [userDetailById, setUserDetailById] = useState(emptyUserDetail);
  const [open, setOpen] = useState(false);
  const [buttonType, setButtonType] = useState("");
  const dispatch = useDispatch();
  const params = useParams();

  // if (!tableDef.some((o) => o.headerName === "Action")) {
  //   tableDef.unshift({
  //     headerName: "Action",
  //     maxWidth: 120,
  //     cellRenderer: (params) => (
  //       <Box display="flex" alignItems="center" sx={{ width: "100px" }}>
  //         <IconButton
  //           onClick={() => {
  //             setUserDetailById(params.data);
  //             // setOpen(true);
  //             // dispatch({ type: "VIEW", data: params.data });
  //           }}
  //         >
  //           <Edit color="primary" />
  //         </IconButton>
  //         <IconButton
  //           onClick={() => {
  //             // handleDelete(params.data.id);
  //           }}
  //         >
  //           <Delete color="primary" />
  //         </IconButton>
  //       </Box>
  //     ),
  //   });
  // }

  const loadMasterData = () => {
    getUserById(params.id, user.token)
      .then((res) => {
        setData(res.data.user);
        setMappingDetails(res.data.mappingDetails);
      })
      .catch((err) => {
        handleAxiosError(err, dispatch);
      });
  };
  const handleSave = async (allData) => {
    try {
      const { data } = await updateUsers(
        user.token,
        getUpdatableUserData(allData),
        allData.id
      );

      dispatch(showAlert(data.msg));
      loadMasterData();
      if (allData.id === user.id) {
        dispatch(getUser(user.token));
      }
      setUpdateFormData(emptyUpdateFormData);
    } catch (error) {
      setUpdateFormData(emptyUpdateFormData);
      handleAxiosError(error, dispatch);
    }
  };

  const handleDelete = async (allData) => {
    delete allData.email;
    try {
      const { data } = await deletMappingeUserDetail(user.token, allData.id);
      dispatch(showAlert(data.msg));
      loadMasterData();
      setOpen(false);
      setUserDetailById(emptyUserDetail);
    } catch (error) {
      setUpdateFormData(emptyUpdateFormData);
      setOpen(false);
      handleAxiosError(error, dispatch);
    }
  };
  const handleAddUpdate = async (allData) => {
    try {
      if (allData.hasOwnProperty("id")) {
        const { data } = await updateMappingUserDetail(
          user.token,
          getMappingUpdatableUserData(allData),
          allData.id
        );
        dispatch(showAlert(data.msg));
        loadMasterData();
        setOpen(false);
        setUserDetailById(emptyUserDetail);
      } else {
        allData["email"] = data.email;
        let newData = { ...allData, customer_id: params.id };
        const response = await addMappingUserDetail(
          user.token,
          getMappingUpdatableUserData(newData)
        );
        dispatch(showAlert(response.data.msg));
        loadMasterData();
        setOpen(false);
        setUserDetailById(emptyUserDetail);
      }
    } catch (error) {
      setUpdateFormData(emptyUpdateFormData);
      setOpen(false);
      handleAxiosError(error, dispatch);
    }
  };

  useEffect(() => {
    loadMasterData();
  }, []);
  const onInputChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <Box sx={{ flexGrow: 1, p: 5 }}>
      <Typography variant="h6" className="mui-heading">
        Update User
      </Typography>
      <Box component="form" onSubmit={handleSave}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              label="First Name"
              name="firstname"
              type="text"
              fullWidth
              size="small"
              onChange={onInputChange}
              value={data.firstname}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Last Name"
              name="lastname"
              type="text"
              fullWidth
              size="small"
              onChange={onInputChange}
              value={data.lastname}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Email"
              name="email"
              type="text"
              fullWidth
              size="small"
              onChange={onInputChange}
              value={data.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Role"
              name="role"
              type="text"
              variant="outlined"
              fullWidth
              size="small"
              select
              value={data.role}
              onChange={onInputChange}
              disabled={user.role === "enterpriseadmin" ? false : true}
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="superadmin">Superadmin</MenuItem>
              <MenuItem value="customer">Customer</MenuItem>
              <MenuItem value="enterpriseadmin">Enterprise Admin</MenuItem>
            </TextField>
          </Grid>
          {data.role === "customer" ? (
            <Grid item xs={12} md={6}>
              <TextField
                label="Customer Name"
                name="customer_name"
                type="text"
                fullWidth
                size="small"
                onChange={onInputChange}
                value={data.customer_name}
              />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} md={6}>
            <TextField
              label="Country"
              name="country"
              type="text"
              fullWidth
              select
              size="small"
              onChange={({ target }) => {
                let selected = CountryCodes.find(
                  (c) => c.country === target.value
                );
                setData((prev) => ({
                  ...prev,
                  country: selected.country,
                  country_code: selected.code,
                }));
              }}
              value={data.country}
            >
              <MenuItem value="">Select</MenuItem>
              {CountryCodes.map((c, i) => (
                <MenuItem key={i} value={c.country}>
                  {c.country}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Country Code"
              name="country_code"
              type="text"
              variant="outlined"
              fullWidth
              size="small"
              select
              value={data.country_code}
              onChange={({ target }) => {
                let selected = CountryCodes.find(
                  (c) => c.code === target.value
                );
                setData((prev) => ({
                  ...prev,
                  country: selected.country,
                  country_code: selected.code,
                }));
              }}
            >
              <MenuItem value="">Select</MenuItem>
              {CountryCodes.map((data, key) => (
                <MenuItem key={key} value={data.code}>
                  +{data.code}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Contact"
              name="contact"
              type="text"
              size="small"
              inputProps={{
                maxLength: 14,
              }}
              fullWidth
              onChange={onInputChange}
              value={data.contact}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Street"
              name="street"
              size="small"
              type="text"
              fullWidth
              onChange={onInputChange}
              value={data.street}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="City"
              name="city"
              size="small"
              type="text"
              fullWidth
              onChange={onInputChange}
              value={data.city}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="State"
              name="state"
              type="text"
              size="small"
              fullWidth
              onChange={onInputChange}
              value={data.state}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Zip/Postal Code"
              name="zip_code"
              type="text"
              inputProps={{
                maxLength: 7,
              }}
              size="small"
              fullWidth
              onChange={onInputChange}
              value={data.zip_code}
            />
          </Grid>
          <Grid item xs={12} md={12} xl={12}>
            <Box
              display="flex"
              pt={1}
              pr={1}
              justifyContent="flex-end"
              columnGap={1}
            >
              {/* <Link to="/users" style={{ textDecoration: "none" }}>
                <Button className="cancel-button">Cancel</Button>
              </Link> */}
              <Button
                className="red-button submit"
                size="small"
                onClick={() => {
                  handleSave(data);
                }}
              >
                Update
              </Button>
            </Box>
          </Grid>
          {data.role === "customer" ? (
            <Box sx={{ flexGrow: 1 }}>
              <Paper className="paper-box-shadow" sx={{ p: "1rem" }}>
                <Stack
                  width={500}
                  pt={2}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" className="mui-heading">
                    Mapping Details
                  </Typography>
                  <Box height={50}>
                    <Button
                      className="red-button submit"
                      size="small"
                      onClick={() => {
                        delete userDetailById.id;

                        setOpen(true);
                        setButtonType("Add");
                      }}
                    >
                      Add
                    </Button>
                  </Box>
                </Stack>

                <div
                  className="ag-theme-material"
                  style={{ height: "300px", width: "500px" }}
                >
                  <AgGridReact
                    columnDefs={tableDef}
                    rowData={mappingDetails}
                    onCellClicked={(params) => {
                      if (params.colDef.field === "bt_cus_id") {
                        setUserDetailById(params.data);
                        setOpen(true);
                        setButtonType("Update");
                      }
                      return;
                    }}

                    // rowSelection="single"
                    // onRowClicked={(params) => { setSelectedProjectRow(params.data.ep_id) }}
                  />
                </div>

                <AddUpdateUserDetails
                  open={open}
                  setOpen={setOpen}
                  buttonType={buttonType}
                  userDetailById={userDetailById}
                  setUserDetailById={setUserDetailById}
                  handleSave={handleAddUpdate}
                  handleDelete={handleDelete}
                />
              </Paper>
            </Box>
          ) : (
            <></>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

function getUpdatableUserData(data) {
  return {
    firstname: data.firstname,
    lastname: data.lastname,
    email: data.email,
    role: data.role,
    country: data.country,
    customer_name: data.customer_name,
    country_code: data.country_code,
    contact: data.contact,
    street: data.street,
    city: data.city,
    state: data.state,
    zip_code: data.zip_code,
  };
}
function getMappingUpdatableUserData(data) {
  return {
    bt_cus_id: data.bt_cus_id,
    customer_name: data.customer_name,
    email: data.email,
    customer_id: data.customer_id,
  };
}

// import React from "react";
// import Button from "@mui/material/Button";
// import TextField from "@mui/material/TextField";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import { Grid, MenuItem } from "@mui/material";
// import { CountryCodes } from "../../CountryPhoneCodes";

// export default function UpdateUser({
//   isOpen,
//   handleClose,
//   handleSave,
//   data,
//   setData,
// }) {
//   const onInputChange = (e) => {
//     setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
//   };

//   return (
//     <div>
//       <Dialog open={isOpen} onClose={handleClose} fullWidth={true}>
//         <DialogTitle> {data.id ? "Update User" : "Add User"}</DialogTitle>
//         <DialogContent dividers={true}>
//           <Grid container spacing={1}>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 label="First Name"
//                 name="firstname"
//                 type="text"
//                 fullWidth
//                 size="small"
//                 onChange={onInputChange}
//                 value={data.firstname}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 label="Last Name"
//                 name="lastname"
//                 type="text"
//                 fullWidth
//                 size="small"
//                 onChange={onInputChange}
//                 value={data.lastname}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 label="Email"
//                 name="email"
//                 type="text"
//                 fullWidth
//                 size="small"
//                 onChange={onInputChange}
//                 value={data.email}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 label="Role"
//                 name="role"
//                 type="text"
//                 variant="outlined"
//                 fullWidth
//                 size="small"
//                 select
//                 value={data.role}
//                 onChange={onInputChange}
//               >
//                 <MenuItem value="">Select</MenuItem>
//                 <MenuItem value="admin">Admin</MenuItem>
//                 <MenuItem value="superadmin">Superadmin</MenuItem>
//                 <MenuItem value="customer">Customer</MenuItem>
//                 <MenuItem value="enterpriseadmin">Enterprise Admin</MenuItem>
//               </TextField>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 label="Country"
//                 name="country"
//                 type="text"
//                 fullWidth
//                 select
//                 size="small"
//                 onChange={({ target }) => {
//                   let selected = CountryCodes.find(
//                     (c) => c.country === target.value
//                   );
//                   setData((prev) => ({
//                     ...prev,
//                     country: selected.country,
//                     country_code: selected.code,
//                   }));
//                 }}
//                 value={data.country}
//               >
//                 <MenuItem value="">Select</MenuItem>
//                 {CountryCodes.map((c, i) => (
//                   <MenuItem key={i} value={c.country}>
//                     {c.country}
//                   </MenuItem>
//                 ))}
//               </TextField>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 label="Country Code"
//                 name="country_code"
//                 type="text"
//                 variant="outlined"
//                 fullWidth
//                 size="small"
//                 select
//                 value={data.country_code}
//                 onChange={({ target }) => {
//                   let selected = CountryCodes.find(
//                     (c) => c.code === target.value
//                   );
//                   setData((prev) => ({
//                     ...prev,
//                     country: selected.country,
//                     country_code: selected.code,
//                   }));
//                 }}
//               >
//                 <MenuItem value="">Select</MenuItem>
//                 {CountryCodes.map((data, key) => (
//                   <MenuItem key={key} value={data.code}>
//                     +{data.code}
//                   </MenuItem>
//                 ))}
//               </TextField>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 label="Contact"
//                 name="contact"
//                 type="text"
//                 size="small"
//                 inputProps={{
//                   maxLength: 14,
//                 }}
//                 fullWidth
//                 onChange={onInputChange}
//                 value={data.contact}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 label="Street"
//                 name="street"
//                 size="small"
//                 type="text"
//                 fullWidth
//                 onChange={onInputChange}
//                 value={data.street}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 label="City"
//                 name="city"
//                 size="small"
//                 type="text"
//                 fullWidth
//                 onChange={onInputChange}
//                 value={data.city}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 label="State"
//                 name="state"
//                 type="text"
//                 size="small"
//                 fullWidth
//                 onChange={onInputChange}
//                 value={data.state}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 label="Zip/Postal Code"
//                 name="zip_code"
//                 type="text"
//                 inputProps={{
//                   maxLength: 7,
//                 }}
//                 size="small"
//                 fullWidth
//                 onChange={onInputChange}
//                 value={data.zip_code}
//               />
//             </Grid>
//           </Grid>
//         </DialogContent>

//         <DialogActions>
//           <Button className="cancel-button" onClick={handleClose}>
//             Cancel
//           </Button>
//           <Button
//             className="red-button submit"
//             size="small"
//             onClick={() => {
//               handleSave(data);
//             }}
//           >
//             Update
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }
