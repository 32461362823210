import React from "react";
import {
  Paper,
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import EditProfileDialog from "./EditProfileDialog";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile, getProfile } from "../../../api/updateProfile";
import { handleAxiosError } from "../../../config/action-config";
import { getUser } from "../../../actions/auth";
import { useNavigate } from "react-router-dom";

const emptyUpdateFormData = {
  firstname: "",
  lastname: "",
  email: "",
  country: "",
  country_code: "",
  contact: "",
  street: "",
  city: "",
  state: "",
  zip_code: "",
};

export default function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [updateFormData, setUpdateFormData] = useState(emptyUpdateFormData);
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);

  const setProfileUpdateData = (user) => {
    setUpdateFormData({
      firstname: user.firstname,
      lastname: user.lastname,
      street: user.street,
      country: user.country,
      country_code: user.country_code,
      contact: user.contact,
      city: user.city,
      state: user.state,
      zip_code: user.zip_code,
      email: user.email,
    });
  };

  useEffect(() => {
    if (user.token)
      getProfile(user.token)
        .then(({ data }) => {
          setProfileData(data.data);
        })
        .catch((err) => {
          handleAxiosError(err, dispatch);
        })
        .finally(() => {
          setLoading(false);
        });
  }, [user.token]);

  const handleSave = async (allData) => {
    try {
      const res = await updateProfile(user.token, allData, user.id);
      if (res?.data?.status) {
        alert(res.data.msg);
        setUpdateFormData(emptyUpdateFormData);
        dispatch(getUser(user.token));
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 5 }}>
      <Paper elevation={3} sx={{ p: 1 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box pl={1}>
            <Typography variant="h6" fontWeight={600}>
              Profile
            </Typography>
          </Box>
          <Box display="flex" columnGap={1}>
            {/* {/admin/i.test(user.role) ? ( */}
            <Button
              variant="contained"
              size="small"
              className="pr-btn"
              onClick={() => setProfileUpdateData(user)}
            >
              Edit Profile
            </Button>
            {/* // ) : (
            //   <></>
            // )} */}
            <Button
              variant="contained"
              size="small"
              className="pr-btn"
              onClick={() => navigate("/updatePassword", { replace: true })}
            >
              Change Password
            </Button>
            {!user.mfa ? (
              <Button
                variant="contained"
                size="small"
                className="pr-btn"
                onClick={() => navigate("/enable-mfa", { replace: true })}
              >
                Enable MFA
              </Button>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Paper>
      <br />
      {loading ? (
        <CircularProgress />
      ) : (
        <Paper sx={{ p: 2 }} elevation={3}>
          <EditProfileDialog
            isOpen={Boolean(updateFormData.firstname)}
            handleClose={() => {
              setUpdateFormData(emptyUpdateFormData);
            }}
            data={updateFormData}
            setData={setUpdateFormData}
            handleSave={handleSave}
          />

          {/admin/i.test(user.role) ? (
            <Grid container spacing={2} id="info-grid">
              <ProfileInfo
                label="First Name"
                className="capitalize"
                value={user.firstname}
              />
              <ProfileInfo
                label="Last Name"
                className="capitalize"
                value={user.lastname}
              />
              <ProfileInfo label="Email" value={user.email} />
              <ProfileInfo label="Country Code" value={user.country_code} />
              <ProfileInfo label="Contact" value={user.contact} />
              <ProfileInfo
                label="Country"
                className="capitalize"
                value={user.country}
              />
              <ProfileInfo
                label="Street"
                className="capitalize"
                value={user.street}
              />
              <ProfileInfo
                label="City"
                className="capitalize"
                value={user.city}
              />
              <ProfileInfo
                label="State"
                className="capitalize"
                value={user.state}
              />
              <ProfileInfo label="Zip/Postal Code" value={user.zip_code} />
            </Grid>
          ) : (
            <Grid container spacing={2} id="info-grid">
              <ProfileInfo
                label="First Name"
                className="capitalize"
                value={user.firstname}
              />
              <ProfileInfo
                label="Last Name"
                className="capitalize"
                value={user.lastname}
              />
              <ProfileInfo
                label="Customer Name"
                className="capitalize"
                value={profileData.customer_name}
              />
              <ProfileInfo
                label="Address 1"
                className="capitalize"
                value={profileData.address1}
              />
              <ProfileInfo
                label="Address 2"
                className="capitalize"
                value={profileData.address2}
              />
              <ProfileInfo
                label="Sales Person"
                className="capitalize"
                value={profileData.salespsn}
              />
              <ProfileInfo label="Email" value={profileData.email} />
              <ProfileInfo
                label="Contact"
                className="capitalize"
                value={profileData.contact}
              />
              <ProfileInfo
                label="Fax"
                className="capitalize"
                value={profileData.fax}
              />
              <ProfileInfo
                label="Country Code"
                className="capitalize"
                value={profileData.country_code}
              />
              <ProfileInfo
                label="Country"
                className="capitalize"
                value={profileData.country}
              />
              <ProfileInfo
                label="State"
                className="capitalize"
                value={profileData.state}
              />
              <ProfileInfo
                label="Zip/Postal Code"
                className="capitalize"
                value={profileData.zip_code}
              />
              <ProfileInfo
                label="City"
                className="capitalize"
                value={profileData.city}
              />
              <ProfileInfo
                label="Street"
                className="capitalize"
                value={profileData.street}
              />
            </Grid>
          )}
        </Paper>
      )}
    </Box>
  );
}

function ProfileInfo({ label, value, ...props }) {
  return value ? (
    <Grid item xs={12} md={6} lg={4}>
      <Typography {...props}>
        {" "}
        <strong>{label}: </strong> {value}
      </Typography>
    </Grid>
  ) : (
    <></>
  );
}

/*
firstname
lastname
email
username
password
country_code
street
contact
city
state
zip_code
role
status
ship_to_cust_id
is_approved
mfa
*/
