import { Box, Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getKpiGraph } from "../../api/kpiGraph";
import { handleAxiosError } from "../../config/action-config";
import { toUSCompact, toUSD } from "../../config/formatters";
export default function Kpi() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [openCustomerSum, setOpenCustomerSum] = useState([]);
  const [openCustomerBill, setOpenCustomerBill] = useState([]);
  const [openOrderSum, setOpenOrderSum] = useState([]);
  const [openOdersDesc, setOpenOdersDesc] = useState([]);
  const [closedCustomerSum, setClosedCustomerSum] = useState([]);
  const [closedCustomerBill, setClosedCustomerBill] = useState([]);
  const [closedOrderSum, setClosedOrderSum] = useState([]);
  const [closedOrderDesc, setClosedOrderDesc] = useState([]);

  useEffect(() => {
    KpiGraphData();
  }, []);

  //api call

  const KpiGraphData = async () => {
    try {
      const { data } = await getKpiGraph(user.token);
      const {
        openOrderTopTenCustomers, openOrderTopTenOrders,
        closedOrderTopTenCustomers, closedOrderTopTenOrders
      } = data.data;

      let openOrderCustomersY = [];
      let openOrderCustomersX = [];
      openOrderTopTenCustomers.forEach((e) => {
        openOrderCustomersY.push(e.sum);
        openOrderCustomersX.push(e.bill_to_name.split(" "));
      });

      let openOrdersY = [];
      let openOrdersX = [];
      openOrderTopTenOrders.forEach((e) => {
        openOrdersY.push(parseInt(e.sum));
        openOrdersX.push(e.product_desc1);
      });

      let closedOrderCustomersY = [];
      let closedOrderCustomersX = [];
      closedOrderTopTenCustomers.forEach((e) => {
        closedOrderCustomersY.push(e.sum);
        closedOrderCustomersX.push(e.bill_to_name.split(" "));
      });

      let closedOrdersY = [];
      let closedOrdersX = [];
      closedOrderTopTenOrders.forEach((e) => {
        closedOrdersY.push(parseInt(e.sum));
        closedOrdersX.push(e.product_desc1);
      });

      setOpenCustomerSum(openOrderCustomersY);
      setOpenCustomerBill(openOrderCustomersX);

      setOpenOrderSum(openOrdersY);
      setOpenOdersDesc(openOrdersX);

      setClosedCustomerSum(closedOrderCustomersY);
      setClosedCustomerBill(closedOrderCustomersX);

      setClosedOrderSum(closedOrdersY);
      setClosedOrderDesc(closedOrdersX);
    } catch (error) { handleAxiosError(error, dispatch) }
  };

  //open order customers
  const openOrderCustomersAreaFill = {
    type: "gradient",
  };
  const openOrderCustomersSeries = [
    {
      name: "Value",
      data: openCustomerSum,
    },
  ];
  const openOrderCustomersOptions = {
    xaxis: {
      categories: openCustomerBill,
      title: {
        text: "Customer Name"
      }
    },
    yaxis: {
      title: {
        text: "Amount (USD)",
      },
      labels: {
        formatter: toUSCompact
      }
    },
    dataLabels: {
      formatter: toUSCompact
    },
    tooltip: {
      y: {
        formatter: toUSD
      }
    },
    title: {
      text: "Open Orders: Top 10 Customers by Amount",
    },
    chart: {
      toolbar: {
        show: false
      }
    }
  };

  // open orders
  const openOrdersSeries = openOrderSum;
  const openOrderOptions = {
    labels: openOdersDesc,
    tooltip: {
      y: {
        formatter: toUSD
      }
    },
    title: {
      text: "Open Orders: Top 10 Ordered Products",
    },
  };


  // Closed Order customers
  const closedOrderCustomersAreaFill = {
    type: "gradient",
  };
  const closedOrderCustomersAreaSeries = [
    {
      name: "Value",
      data: closedCustomerSum,
    },
  ];
  const closedOrderCustomersOptions = {
    xaxis: {
      categories: closedCustomerBill,
      labels: {
        show: true,
        rotate: 0,
        // rotateAlways: true,
      },
      title: {
        text: "Customer Name"
      }
    },
    yaxis: {
      title: {
        text: "Amount (USD)",
      },
      labels: {
        formatter: toUSCompact
      }
    },
    dataLabels: {
      formatter: toUSCompact
    },
    tooltip: {
      y: {
        formatter: toUSD
      }
    },
    title: {
      text: "Closed Orders: Top 10 Customers by Amount",
    },
    chart: {
      toolbar: {
        show: false
      }
    }
  };

  // closed orders
  const closedOrdersSeries =  closedOrderSum;
  const closedOrderOptions = {
    labels: closedOrderDesc,
    tooltip: {
      y: {
        formatter: toUSD
      }
    },
    title: {
      text: "Closed Orders: Top 10 Ordered Products",
    },
  };


  return (
    <Box sx={{ flexGrow: 1, p: 5 }}>
      <Paper className="paper-box-shadow" sx={{ p: "1rem" }}>
        <Grid container spacing={2}>

          <Grid item md={12}>
            <Chart
              className="kpi-area-chart"
              type="area"
              height={400}
              width={'100%'}
              fill={openOrderCustomersAreaFill}
              series={openOrderCustomersSeries}
              options={openOrderCustomersOptions}
            />
          </Grid>


          <Grid item md={12}>
            <Box sx={{ height: 40 }}></Box>
            <Chart
              className="kpi-area-pie"
              type="pie"
              height={400}
              width={'100%'}
              series={openOrdersSeries}
              options={openOrderOptions}
            ></Chart>
          </Grid>


          <Grid item md={12}>
            <Box sx={{ height: 60 }}></Box>
            <Chart
              className="kpi-area-chart-closed"
              type="area"
              height={400}
              // width={1200}
              width={'100%'}
              fill={closedOrderCustomersAreaFill}
              series={closedOrderCustomersAreaSeries}
              options={closedOrderCustomersOptions}
            />
          </Grid>


          <Grid item md={12}>
            <Box sx={{ height: 60 }}></Box>
            <Chart
              className="kpi-pie-chart-closed"
              type="pie"
              height={400}
              //width={700}
              width={'100%'}
              series={closedOrdersSeries}
              options={closedOrderOptions}
            ></Chart>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
