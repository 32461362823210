import React, { useEffect } from "react";
import { userTable as tableDef } from "../../config/grid-columns-defs";
import { useState } from "react";
import { IconButton, Paper } from "@material-ui/core";
import {
  Button,
  Box,
  Typography,
  CircularProgress,
  TextField,
  Stack,
} from "@mui/material";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import SimulateUserTable from "../users/simulateUsersList";
import { getSimulateUser } from "../../actions/auth";

export default function SimulateCustomerList() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const dialogAction = useSelector((state) => state.dialog);
  const navigate = useNavigate();
  const [refreshCount, setRefreshCount] = useState(0);

  const refreshTable = () => {
    setRefreshCount((prev) => prev + 1);
  };

  const [columnDef, setColumnDef] = useState(tableDef);

  const handleSimulate = (data) => {
    console.log("step-1 done");
    dispatch(getSimulateUser(user.token, data, navigate));
  };

  useEffect(() => {
    if (!columnDef.some((o) => o.headerName === "Action")) {
      setColumnDef((prev) => [
        {
          headerName: "Action",
          maxWidth: 120,
          cellRenderer: (params) => (
            <Box display="flex" alignItems="center" sx={{ width: "100px" }}>
              <Button
                onClick={() => {
                  console.log("view by id");
                  //   dispatch({ type: "VIEW", data: params.data });
                  handleSimulate(params.data);
                }}
              >
                Simulate
              </Button>
            </Box>
          ),
        },

        ...prev,
      ]);
    }
  }, [columnDef]);
  useEffect(() => {
    if (!dialogAction.type) return;
    switch (dialogAction.type) {
      case "VIEW":
        dispatch({ type: "" });
        navigate(`${dialogAction.data.id}`);
        break;
      default:
        break;
    }
  }, [dialogAction]);
  const [searchEmail, setSearchEmail] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const onSearchSubmit = (e) => {
    e.preventDefault();
    setCompanyName(searchQuery);
    setEmail(searchEmail);
  };
  const onSearchEmailSubmit = (e) => {
    e.preventDefault();
    setEmail(searchEmail);
  };
  const onSearchChange = (e) => {
    setSearchQuery(e.target.value.toUpperCase());
    if (!e.target.value) setCompanyName("");
  };
  const onSearchEmail = (e) => {
    setSearchEmail(e.target.value);
    if (!e.target.value) setEmail("");
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1, p: 5 }}>
        <Paper className="paper-box-shadow" sx={{ p: "1rem" }}>
          <Box display="flex" pt={4} justifyContent="space-between">
            <Typography fontWeight={600} variant="h5">
              Users
            </Typography>
            <Box component="form" onSubmit={onSearchSubmit} width={300}>
              <TextField
                type="search"
                fullWidth
                size="small"
                label="Search by Customer Name"
                helperText={searchQuery ? "Press Enter to Search" : ""}
                value={searchQuery}
                onChange={onSearchChange}
              />
            </Box>
          </Box>
          <Stack pt={2} direction="row" justifyContent="end">
            <Box component="form" onSubmit={onSearchEmailSubmit} width={300}>
              <TextField
                type="search"
                fullWidth
                size="small"
                label="Search by Email "
                helperText={searchEmail ? "Press Enter to Search" : ""}
                value={searchEmail}
                onChange={onSearchEmail}
              />
            </Box>
          </Stack>

          <SimulateUserTable
            email={email}
            company_name={companyName}
            columns={columnDef}
            token={user.token}
            refreshCount={refreshCount}
          />
        </Paper>
      </Box>
    </div>
  );
}
