import { Box, Button, Container, Paper } from "@material-ui/core";
import { TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { forgetPassword } from "../../api/auth";
import BackgroundImage from "../../images/fabrication.jpg";
import { handleAxiosError } from "../../config/action-config";
import { showAlert } from "../../actions/alert";
import Logo from "../../images/logo-ACT.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: "#ce0715",
    color: "#ffffff",
    fontSize: "12px",
    borderRadius: "18px",
  },
  paperContainer: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function ForgetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const RedirectResetPassword = async (e) => {
    navigate("/resetPassword", {
      state: {
        email: email,
      },
    });
  };
  const HandleForgetPassword = async (e) => {
    e.preventDefault();
    // const postableData = {
    //   email,
    // };

    forgetPassword(email)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(showAlert(res.data.msg));
          RedirectResetPassword();
        } else {
          dispatch(showAlert(res.data.msg));
        }
      })
      .catch((err) => {
        handleAxiosError(err, dispatch);
      });
  };

  return (
    <Paper className={classes.paperContainer}>
      <Container component="main" maxWidth="xs">
        <Box>
          <Paper
            style={{
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              verticalAlign: "middle",
              boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "20px",
            }}
          >
            <Box
              component="img"
              className="header-logo"
              alt="The house from the offer."
              src={Logo}
            />
            <form onSubmit={HandleForgetPassword}>
              <TextField
                variant="outlined"
                type="email"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
                onClick={HandleForgetPassword}
              >
                Submit
              </Button>
            </form>
          </Paper>
        </Box>
      </Container>
    </Paper>
  );
}
