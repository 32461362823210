import { Grid, TextField } from "@mui/material";
// import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function DocData() {
  const location = useLocation();
  const data = location.state.data;

  // useEffect(() => {
  //   const iframe = document.querySelector("iframe");
  //   if (iframe?.src) iframe.src = location.state.file;
  // }, []);

  return (
    <div style={{ margin: 10 }}>
      <Grid container spacing={2} sx={{ pt: "1rem" }}>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            margin="dense"
            label="Attached Document Reference"
            fullWidth
            value={data?.id}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            margin="dense"
            label="Invoice#"
            fullWidth
            value={data?.invoice_no}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            margin="dense"
            label="Customer Name"
            fullWidth
            value={data?.bill_to_name}
            disabled
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <TextField
            margin="dense"
            label="Documnet Type"
            fullWidth
            value={data?.doc_type}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            margin="dense"
            label="Document Mime Type"
            fullWidth
            value={data?.mimetype}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            margin="dense"
            label="Document FileName"
            fullWidth
            value={data?.filename}
            disabled
          />
        </Grid>
      </Grid>
      <iframe
        src=""
        style={{ height: "800px", width: "100%", marginTop: 10 }}
      ></iframe>
    </div>
  );
}
