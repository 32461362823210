import { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enableMfa, validateMfa } from "../../api/auth";

import { Alert, Box, Button, CircularProgress, Grid, Paper, TextField } from "@mui/material";
import { useState } from "react";
import { getUser } from "../../actions/auth";
import { useNavigate } from "react-router-dom";

const initialState = { error: "", loading: true, success: false }
function reducer(state, action) {
    switch (action.type) {
        case "SUCCESS": return { ...initialState, loading: false, success: true };
        case "ERROR": return { error: action.payload, loading: false, success: true };
        default: return state;
    }
}
export default function EnableMfa() {

    const user = useSelector(state => state.user);
    const [localState, dispatch] = useReducer(reducer, initialState);
    const globalDispatch = useDispatch();
    const navigate = useNavigate();

    const [mfaOtp, setMfaOtp] = useState("");

    useEffect(() => {
        let check = sessionStorage.getItem('mfa-sent');
        if (check) {
            dispatch({ type: "SUCCESS" });
        } else {
            enableMfa(user.token).then(() => {
                dispatch({ type: "SUCCESS" });
                sessionStorage.setItem('mfa-sent', true)
            }).catch(error => {
                dispatch({ type: "ERROR", payload: "Some Error Occured! Try Again Later." });
            })
        }
    }, [])

    const onFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const { data } = await validateMfa(mfaOtp, user.token);
            if(data.status) {
                globalDispatch(getUser(user.token));
                alert("MFA Enabled");
                navigate("/profile", { replace: true }); 
            } else {
                dispatch({ type: "ERROR", payload: "Wrong OTP" })
            }
        } catch (error) {
            console.log(error);
            alert(error.message);
        }
    }

    return (
        <Box height="90vh">
            {
                localState.loading ? <CircularProgress /> :
                    <Box component='form' display="flex" justifyContent="center" alignItems="center" onSubmit={onFormSubmit}>
                        <Paper sx={{ p: 1, width: 400 }} elevation={3}>
                            <Grid container rowGap={1}>
                                <Grid item xs={12} lg={12}>
                                    {
                                        Boolean(localState.error) ?
                                            <Alert severity="error">{localState.error}</Alert> :
                                            <Alert severity="success">Enter the OTP sent to {user.email}</Alert>
                                    }
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <TextField size="small" value={mfaOtp} onChange={e => setMfaOtp(e.target.value)}
                                        variant="outlined" fullWidth required label="OTP" autoFocus
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Button type="submit" variant="contained" size="small" fullWidth className="pr-btn">Submit</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
            }
        </Box>
    )
}