import * as authApi from "../api/auth";
import { getSimulateDetail } from "../api/simulate";

import {
  auth as actionType,
  handleAxiosError,
} from "../config/action-config.js";
import { showAlert } from "./alert";

export const getUser = (token) => async (dispatch) => {
  try {
    const { data } = await authApi.fetchTokenUser(token);
    localStorage.setItem("token", token);
    dispatch({
      type: actionType.FETCH_SUCCESS,
      payload: { ...data.data, token },
    });
  } catch (error) {
    // console.log("response not find==", error);
    // handleAxiosError(error);
    localStorage.clear();
    sessionStorage.clear();
    dispatch({ type: actionType.LOGOUT, payload: null });
    window.location.reload();
  }
};
export const getSimulateUser =
  (tokendata, data, navigate) => async (dispatch) => {
    try {
      const res = await getSimulateDetail(tokendata, data.id);

      localStorage.setItem("token", res.data.data.token);
      const token = localStorage.getItem("token");

      dispatch({
        type: actionType.FETCH_SIMULATE,
        payload: { ...res.data.data, token },
      });
      navigate("/profile");
    } catch (error) {
      // handleAxiosError(error);
      localStorage.clear();
      sessionStorage.clear();
      dispatch({ type: actionType.LOGOUT, payload: null });
      window.location.reload();
    }
  };

export const loginWithEmail =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      const { data } = await authApi.emailLogin(email, password);
      if (data.status === true) {
        if (!data.data.mfa) {
          localStorage.setItem("token", data.data.token);
          dispatch({
            type: actionType.LOGIN_SUCCESS,
            payload: { ...data.data },
          });
        } else {
          sessionStorage.setItem("email", data.data.email);
          window.location.replace(window.location.origin + "/verify-mfa");
        }
      } else {
        dispatch(showAlert(data.msg));
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      console.log("erroe", error);
      dispatch({ type: actionType.LOGIN_FAILURE, payload: error });
    }
  };

export const logout = () => async (dispatch) => {
  localStorage.removeItem("token");
  sessionStorage.clear();
  dispatch({ type: actionType.LOGOUT, payload: null });
};
