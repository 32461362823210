import axios from "axios";
import { baseUrl } from "../config/api-config";
import { buildQuery } from "../config/api-config";

export const getArAging = (query, token) => {
  let qs = buildQuery(query);
  let url = `${baseUrl}/agingReport${qs}`;
  return axios.get(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};
