import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOpenOrders, getOpenOrdersDetail } from "../../api/openOrder";
import ControlledTable from "../../components/ControlledTable";
import { openOrderDetailTable as tableDefSecond } from "../../config/grid-columns-defs";
import { openSalesOrderDetailsTable as tableDefFirst } from "../../config/grid-columns-defs";
import { useEffect, useState } from "react";
import { Box, Paper } from "@material-ui/core";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { getOpenOrderCustomers } from "../../api/customersNames";
import ControlledSecondTable from "../../components/ControlledSecondTable";
import { useDispatch, useSelector } from "react-redux";
import { getClosedOrdersDetail } from "../../api/closedOrder";
import { handleAxiosError } from "../../config/action-config";

export default function ClosedOrdersDetailList() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const params = useParams();
  const [totalCountForSecondTable, setTotalCountForSecondTable] = useState(0);
  const [rowsForSecondTable, setRowsForSecondTable] = useState([]);
  const [pageNumberForSecondTable, setPageNumberForSecondTable] = useState(0);
  const [totalCountForFirstTable, setTotalCountForFirstTable] = useState(0);
  const [rowsForFirstTable, setRowsForFirstTable] = useState([]);
  const [pageNumberForFirstTable, setPageNumberForFirstTable] = useState(0);

  useEffect(() => {
    refreshTable();
  }, []);

  const getOrderDetailsRows = async (numRows, startRow) => {
    const token = user.token;
    return getClosedOrdersDetail(
      {
        invoice_no: params.invoice_no,
        limit: numRows,
        offset: startRow,
      },
      token
    )
      .then((res) => {
        setTotalCountForSecondTable(
          parseInt(res.data.data.closedOrderDetails.length) === 0
            ? -1
            : parseInt(res.data.data.closedOrderDetails.length)
        );
        return res.data.data.closedOrderDetails;
      })
      .catch((err) => {
        handleAxiosError(err, dispatch);
        return [];
      });
  };

  const getSalesOrderDetailsRows = async (numRows, startRow) => {
    const token = user.token;

    return getClosedOrdersDetail(
      {
        invoice_no: params.invoice_no,
        limit: numRows,
        offset: startRow,
      },
      token
    )
      .then((res) => {
        setTotalCountForFirstTable(
          parseInt(res.data.data.salesOrderDetails.length) === 0
            ? -1
            : parseInt(res.data.data.salesOrderDetails.length)
        );
        return res.data.data.salesOrderDetails;
      })
      .catch((err) => {
        handleAxiosError(err, dispatch);
        return [];
      });
  };
  const refreshTable = () => {
    const token = user.token;

    getClosedOrdersDetail(
      { invoice_no: params.invoice_no, limit: 10, offset: 0 },
      token
    )
      .then((res) => {
        setTotalCountForFirstTable(
          parseInt(res.data.data.salesTotal) === 0
            ? -1
            : parseInt(res.data.data.salesTotal)
        );
        setRowsForFirstTable(res.data.data.salesOrderDetails);
      })
      .catch((err) => {
        handleAxiosError(err, dispatch);
      });
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1, p: 5 }}>
        <Paper className="paper-box-shadow" sx={{ p: "1rem" }}>
          <Typography variant="h6" className="mui-heading">
            Sales : Invoice #{params.invoice_no}
          </Typography>
          <ControlledTable
            rows={rowsForFirstTable}
            columnDefs={tableDefFirst}
            getRows={getSalesOrderDetailsRows}
            totalCount={totalCountForFirstTable}
            onChange={setRowsForFirstTable}
            onPageNumberChange={setPageNumberForFirstTable}
            pageSize={10}
            pageNumber={pageNumberForFirstTable}
            pagination={true}
            // getRowNodeId={(data) => data?.name || data?.index}
            hasActionButtons={false}
          />
          <Typography variant="h6" className="mui-heading">
            Closed AR Details
          </Typography>
          <ControlledSecondTable
            rows={rowsForSecondTable}
            columnDefs={tableDefSecond}
            getRows={getOrderDetailsRows}
            totalCount={totalCountForSecondTable}
            onChange={setRowsForSecondTable}
            onPageNumberChange={setPageNumberForSecondTable}
            pageSize={10}
            pageNumber={pageNumberForSecondTable}
            pagination={false}
            // getRowNodeId={(data) => data?.name || data?.index}
            hasActionButtons={false}
          />
        </Paper>
      </Box>
    </div>
  );
}
