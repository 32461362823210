import React from 'react';
import { Grid, Button } from '@mui/material';
import { useSelector } from "react-redux";

function Footer() {
  const user = useSelector((state) => state.user);

  return (
    <>
      <Grid container spacing={2} className="footer-box">
        <Grid item xs={12} sm={12} className='footer-text'>
          Powered by&nbsp;<Button className='footer-link' onClick={() => window.open('https://vastedge.com/?ref=actpipe&id=' + `${user.id}`, '_blank')}>Vast Edge</Button>
        </Grid>
      </Grid>
    </>
  );
}

export default Footer;

