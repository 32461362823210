import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import BackgroundImage from "../../images/fabrication.jpg";
import Logo from "../../images/logo-ACT.png";

import { TextField, Paper, Container, InputAdornment } from "@mui/material";
import { Button } from "@material-ui/core";

import { Box } from "@mui/system";
import { loginWithEmail } from "../../actions/auth";

import { AccountCircle } from "@mui/icons-material";
import { Lock } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: "#ce0715",
    color: "#ffffff",
    fontSize: "12px",
    borderRadius: "18px",
  },
  paperContainer: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px",
    padding: "0px",
  },
}));

export default function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({ email: "", password: "" });
  const onInputChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const { isLoggedIn } = useSelector((state) => state.user);

  const onFormSubmit = (e) => {
    e.preventDefault();
    dispatch(loginWithEmail(data));
  };

  return isLoggedIn ? (
    <Navigate to="/" replace />
  ) : (
    <Paper className={classes.paperContainer}>
      <Container maxWidth="xs">
        <Box>
          <Paper
            style={{
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              verticalAlign: "middle",
              boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "20px",
            }}
          >
            <Box component="img" className="header-logo" alt="" src={Logo} />
            <form onSubmit={onFormSubmit}>
              <TextField
                variant="outlined"
                type="email"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={data.email}
                onChange={onInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                type="password"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                id="password"
                value={data.password}
                onChange={onInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
                onClick={() => navigate("/forgetPassword", { replace: true })}
              >
                Reset Password
              </Button>
            </form>
          </Paper>
        </Box>
      </Container>
    </Paper>
  );
}
