import axios from "axios";
import { baseUrl } from "../config/api-config";
export const updateProfile = (token, payload, id) => {
  let url = `${baseUrl}/user/${id}`;
  return axios.patch(
    url,
    { ...payload },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getProfile = (token) => {
  return axios.get(`${baseUrl}/profile`, {
    headers: {
      authorization: `Bearer ${token}`
    }
  })
}