import axios from "axios";
import { baseUrl, buildQuery } from "../config/api-config";

export const getUsers = (query, token) => {
  let qs = buildQuery(query);
  let url = `${baseUrl}/users${qs}`;
  return axios.get(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};
export const getUserById = (id, token) => {
  let url = `${baseUrl}/user/${id}`;
  return axios.get(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const addUsers = (token, payload) => {
  let url = `${baseUrl}/user`;
  return axios.post(
    url,
    { ...payload },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
};
export const updateUsers = (token, payload, id) => {
  let url = `${baseUrl}/user/${id}`;
  return axios.patch(
    url,
    { ...payload },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
};
export const deleteUsers = (token, id) => {
  let url = `${baseUrl}/user/${id}`;
  return axios.delete(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const approveCustomers = (token, ids) => {
  return axios.post(
    `${baseUrl}/customers-approve`,
    { users: ids },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
};

///userDetail/////////

export const addMappingUserDetail = (token, payload) => {
  let url = `${baseUrl}/addCustId`;
  return axios.post(
    url,
    { ...payload },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
};
export const updateMappingUserDetail = (token, payload, id) => {
  let url = `${baseUrl}/updateCustId/${id}`;
  return axios.patch(
    url,
    { ...payload },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
};
export const deletMappingeUserDetail = (token, id) => {
  let url = `${baseUrl}/deleteCustId/${id}`;
  return axios.delete(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};
