import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, MenuItem } from "@mui/material";
import { CountryCodes } from "../../../CountryPhoneCodes";

export default function UpdateUser({
  isOpen,
  handleClose,
  handleSave,
  data,
  setData,
}) {
  const onInputChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} fullWidth={true}>
        <DialogTitle> {data.id ? "Update User" : "Add User"}</DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                label="First Name"
                name="firstname"
                type="text"
                fullWidth
                size="small"
                onChange={onInputChange}
                value={data.firstname}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Last Name"
                name="lastname"
                type="text"
                fullWidth
                size="small"
                onChange={onInputChange}
                value={data.lastname}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email"
                name="email"
                type="text"
                fullWidth
                size="small"
                onChange={onInputChange}
                value={data.email}
                disabled
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <TextField
                label="Role"
                name="role"
                type="text"
                variant="outlined"
                fullWidth
                size="small"
                select
                value={data.role}
                onChange={onInputChange}
              >
                <MenuItem value="">
                  Select
                </MenuItem>
                {role.map((data, key) => (
                  <MenuItem key={key} value={data.Type}>
                    {data.Type}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}
            <Grid item xs={12} md={6}>
              <TextField
                label="Country"
                name="country"
                type="text"
                fullWidth
                select
                size="small"
                onChange={({ target }) => {
                  let selected = CountryCodes.find(
                    (c) => c.country === target.value
                  );
                  setData((prev) => ({
                    ...prev,
                    country: selected.country,
                    country_code: selected.code,
                  }));
                }}
                value={data.country}
              >
                <MenuItem value="">Select</MenuItem>
                {CountryCodes.map((c, i) => (
                  <MenuItem key={i} value={c.country}>
                    {c.country}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Country Code"
                name="country_code"
                type="text"
                variant="outlined"
                fullWidth
                size="small"
                select
                value={data.country_code}
                onChange={({ target }) => {
                  let selected = CountryCodes.find(
                    (c) => c.code === target.value
                  );
                  setData((prev) => ({
                    ...prev,
                    country: selected.country,
                    country_code: selected.code,
                  }));
                }}
              >
                <MenuItem value="">Select</MenuItem>
                {CountryCodes.map((data, key) => (
                  <MenuItem key={key} value={data.code}>
                    +{data.code}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Contact"
                name="contact"
                type="text"
                size="small"
                inputProps={{
                  maxLength: 14,
                }}
                fullWidth
                onChange={onInputChange}
                value={data.contact}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Street"
                name="street"
                size="small"
                type="text"
                fullWidth
                onChange={onInputChange}
                value={data.street}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="City"
                name="city"
                size="small"
                type="text"
                fullWidth
                onChange={onInputChange}
                value={data.city}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="State"
                name="state"
                type="text"
                size="small"
                fullWidth
                onChange={onInputChange}
                value={data.state}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Zip/Postal Code"
                name="zip_code"
                type="text"
                inputProps={{
                  maxLength: 7,
                }}
                size="small"
                fullWidth
                onChange={onInputChange}
                value={data.zip_code}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            className="pr-btn"
            variant="contained"
            size="small"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="pr-btn"
            size="small"
            variant="contained"
            onClick={() => {
              handleSave(data);
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
