import { useState, useEffect } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import { CircularProgress } from "@mui/material";
import { getArAging } from "../../api/arAgingList";


export default function ArAgingTable({ columns, perPage = 10, token, customerName }) {

    const [gridApi, setGridApi] = useState(null);
    const [noTableMsg, setNoTableMsg] = useState(".");
    const [loading, setLoading] = useState(false);

    const onGridReady = (params) => {
        setGridApi(params.api);
    }

    useEffect(() => {
        if (gridApi) {
            setLoading(true);
            const dataSource = {
                getRows: async (params) => {
                    try {
                        const { data } = await getArAging({ limit: perPage, offset: params.endRow - perPage, customerName }, token);
                        setNoTableMsg("");
                        setLoading(false);
                        params.successCallback(data.data.records, parseInt(data.data.records[0].totalrecords))
                    } catch (error) {
                        setNoTableMsg("Error Occured While Loading Data");
                        setLoading(false);
                        params.successCallback([], 0);
                    }
                }
            }
            gridApi.setDatasource(dataSource);
        }
    }, [gridApi, perPage, customerName])

    return (
        <>
            {Boolean(noTableMsg)? <h4>{noTableMsg}</h4> : <></> }
            <br/>
            {
                loading ? <CircularProgress /> : <></>
            }
            <div
                aria-live="polite"
                aria-busy={false}
                className="ag-theme-material"
                style={{ visibility: Boolean(noTableMsg || loading) ? 'hidden' : 'visible' }}
            >
                <AgGridReact
                    rowHeight={30}
                    columnDefs={columns}
                    pagination={true}
                    rowModelType="infinite"
                    paginationPageSize={perPage}
                    cacheBlockSize={perPage}
                    onGridReady={onGridReady}
                    domLayout="autoHeight"
                />
            </div>
        </>
    )
}