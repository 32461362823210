import React, { useRef, useCallback, useState, useEffect } from "react";

import CustomerOrderTable from "./ClosedCustomerOrderTable.jsx";
import { openOrderTable as tableDef } from "../../config/grid-columns-defs";

import {
  Grid,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { useSelector } from "react-redux";
import { handleAxiosError } from "../../config/action-config";
import { useDispatch } from "react-redux";
import { Box, Paper } from "@material-ui/core";
import { getPdfViewer } from "../../api/fileView";
import useClosedCustomerSearch from "./useClosedCustomerSearch";
import { getOpenOrderVariants } from "../../api/variants.js";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
const limit = 10;

export default function ClosedOrdersDataList() {
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState({
    id: "",
    name: "",
  });
  const [fileLoader, setFileLoader] = useState(false);
  const [variants, setVariants] = useState([]);
  const [invoice, setInvoice] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  useEffect(() => {
    getVariants();
  }, []);

  const getVariants = async () => {
    console.log("getvariants------------");
    try {
      const { data } = await getOpenOrderVariants(user.token);
      console.log("variants", data.data.variants);
      setVariants(data.data.variants);
    } catch (error) {
      handleAxiosError(error, dispatch);
    }
  };

  // only if user is customer
  useEffect(() => {
    if (/customer/i.test(user.role)) {
      setSelectedCustomer({ id: user.bt_cust_id, name: user.company_name });
    }
  }, [user]);

  const [search, setSearch] = useState("");
  let columnCheck = tableDef.some((o) => o.headerName === "Attached Document");
  if (!columnCheck) {
    tableDef.push({
      headerName: "Attached Document",
      field: "filename",
      resizable: true,
      minWidth: 180,
      onCellClicked: (params) => {
        getPdfViewerData(params.data.filename, params.data.mimetype);
      },
    });
  }

  const getPdfViewerData = (filename, mimetype, data) => {
    setFileLoader(true);
    getPdfViewer({ filename: filename, content: mimetype }, user.token)
      .then((blob) => {
        const file = window.URL.createObjectURL(blob.data);
        setFileLoader(false);
        window.open(file, "_blank");
        return;
        // const iframe = document.querySelector("iframe");
        // if (iframe?.src) iframe.src = file;
        // navigate("/docViewer", {
        //   state: {
        //     data: data,
        //     file: file,
        //   },
        // });
      })
      .catch((err) => {
        handleAxiosError(err, dispatch);
      });
  };

  const { loading, customerList, hasMore } = useClosedCustomerSearch(
    search,
    pageNumber,
    user.token
  );
  const observer = useRef();
  const lastCustomerElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prev) => {
            return prev + 1;
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleSearchValueChange = (e) => {
    setPageNumber(1);
    setSearch(e.target.value.toUpperCase());
  };

  function onVariantChange({ target }) {
    const { value } = target;
    const [id, name] = value.split(" @ ");
    setSelectedCustomer({ id, name });
  }
  // Handler for invoice filter change
  const handleInvoiceChange = (e) => {
    setInvoice(e.target.value);
  };

  // Handler for from date filter change
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  // Handler for to date filter change
  const handleToDateChange = (date) => {
    setToDate(date);
  };
  return (
    <div>
      <Box sx={{ flexGrow: 1, p: 5 }}>
        <Paper className="paper-box-shadow" sx={{ p: "1rem" }}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item md={6} lg={6}>
              <Typography variant="h6" className="mui-heading">
                Closed Orders - {selectedCustomer.name}
              </Typography>
            </Grid>

            {/customer/i.test(user.role) ? (
              <Grid item md={6} lg={4} xl={3} id="search-area">
                {variants.length ? (
                  <TextField
                    size="small"
                    select
                    label="Select Customer"
                    fullWidth
                    onChange={onVariantChange}
                    value={`${selectedCustomer.id} @ ${selectedCustomer.name}`}
                  >
                    {variants.map((v, i) => (
                      <MenuItem
                        key={i}
                        value={`${v.bt_cus_id} @ ${v.customer_name}`}
                      >
                        {v.bt_cus_id} - {v.customer_name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <></>
                )}
              </Grid>
            ) : (
              <Grid item md={6} lg={4} xl={3} id="search-area">
                <TextField
                  value={search}
                  onChange={handleSearchValueChange}
                  variant="outlined"
                  fullWidth
                  size="small"
                  label="Search Customer"
                  onFocus={() => {
                    document.getElementById("drop-box").classList.add("block");
                  }}
                  onBlur={() => {
                    document
                      .getElementById("drop-box")
                      .classList.remove("block");
                  }}
                  InputProps={{
                    endAdornment: loading ? (
                      <InputAdornment position="end">
                        <CircularProgress size="1.5rem" />
                      </InputAdornment>
                    ) : (
                      <></>
                    ),
                  }}
                />
                <Paper style={{ position: "relative" }}>
                  <Box
                    maxHeight={200} // onMouseOut={() => { setShowDropdown(false) }}
                    minHeight={Math.min(customerList.length * 30, 200)}
                    id="drop-box"
                    style={{ overflowY: "scroll" }}
                    zIndex={4}
                    sx={{ background: "#fff", boxShadow: "2px 2px 5px gray" }}
                    position="absolute"
                    width="100%"
                  >
                    {customerList.map((c, idx) => {
                      if (customerList.length === idx + 1) {
                        return (
                          <div
                            key={idx}
                            ref={lastCustomerElementRef}
                            className="c-list-item"
                            onClick={() => {
                              setSelectedCustomer({
                                id: c.cust_id,
                                name: c.customer_name,
                              });
                            }}
                          >
                            {c.cust_id} - {c.customer_name}
                          </div>
                        );
                      }
                      return (
                        <div
                          key={idx}
                          className="c-list-item"
                          onClick={() => {
                            setSelectedCustomer({
                              id: c.cust_id,
                              name: c.customer_name,
                            });
                          }}
                        >
                          {c.cust_id} - {c.customer_name}
                        </div>
                      );
                    })}
                  </Box>
                </Paper>
              </Grid>
            )}
            {search && (
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item>
                  <TextField
                    value={invoice}
                    onChange={handleInvoiceChange}
                    variant="outlined"
                    size="small"
                    label="Filter By Invoice"
                  />
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      label="From Date"
                      value={fromDate}
                      onChange={handleFromDateChange}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      label="To Date"
                      value={toDate}
                      onChange={handleToDateChange}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            )}
          </Grid>
          {fileLoader ? (
            <Box display="flex" alignItems="center">
              <CircularProgress size="2rem" />
              <Typography sx={{ ml: 2 }}> Fetching file ... </Typography>
            </Box>
          ) : (
            <></>
          )}
          <CustomerOrderTable
            // setVariants={setVariants}
            columns={tableDef}
            perPage={limit}
            token={user.token}
            selectedCustomer={selectedCustomer}
            invoice={invoice}
            fromDate={fromDate}
            toDate={toDate}
          />
        </Paper>
      </Box>
    </div>
  );
}
