import axios from "axios";
import { baseUrl, buildQuery } from "../config/api-config";

export const getOpenOrders = (query, token) => {
  let qs = buildQuery(query);
  let url = `${baseUrl}/openOrders${qs}`;
  return axios.get(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};
export const getOpenOrdersDetail = (query, token) => {
  let qs = buildQuery(query);
  let url = `${baseUrl}/openOrderDetails${qs}`;
  return axios.get(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};
