import { Box, Button, Container, Paper } from "@material-ui/core";
import { TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "../../api/auth";
import { handleAxiosError } from "../../config/action-config";
import { showAlert } from "../../actions/alert";
import { logout } from "../../actions/auth";
import Logo from "../../images/logo-ACT.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: "#ce0715",
    color: "#ffffff",
    fontSize: "12px",
    borderRadius: "18px",
  },
  paperContainer: {
    // backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function UpdatePassword() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const classes = useStyles();
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const HandleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const res = await updatePassword(oldPassword, newPassword, user.token);
      if (res.data.status === true) {
        dispatch(showAlert(res.data.msg));
        dispatch(logout());
      } else {
        dispatch(showAlert(res.data.msg));
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
    }
  };

  return (
    <Paper className={classes.paperContainer}>
      <Container component="main" maxWidth="xs">
        <Box>
          <Paper
            style={{
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              verticalAlign: "middle",
              boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "20px",
            }}
          >
            <Box
              component="img"
              className="header-logo"
              alt="The house from the offer."
              src={Logo}
            />
            <form onSubmit={HandleResetPassword}>
              <TextField
                variant="outlined"
                type="password"
                margin="normal"
                required
                fullWidth
                name="oldPassword"
                label="Old Password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <TextField
                variant="outlined"
                type="password"
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="New Password"
                id="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
                onClick={HandleResetPassword}
              >
                Reset Password
              </Button>
            </form>
          </Paper>
        </Box>
      </Container>
    </Paper>
  );
}
