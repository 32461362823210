import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  DialogContentText,
  Button,
  DialogActions,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { hideAlert } from "../actions/alert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertBox() {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);

  const handleClose = () => {
    if (alert.onClose) alert.onClose();
    dispatch(hideAlert());
  };

  return (
    <Dialog
      open={alert.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{alert.isError ? "Error" : "Alert"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {alert.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="red-button add-user" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
