import { TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getArAging } from "../../api/arAgingList";

import { handleAxiosError } from "../../config/action-config";
import { arAgingTable as tableDef } from "../../config/grid-columns-defs";
import { Box, Paper } from "@material-ui/core";
import { toUSCompact, toUSD } from "../../config/formatters";
import ArAgingTable from "./ArAgingTable";



export default function ARAging() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [openCustomerBill, setOpenCustomerBill] = useState([]);
  const [zeroDays, setZeroDays] = useState([]);
  const [thirtyOneDays, setThirtyOneDays] = useState([]);
  const [sixtyOneDays, setSixtyOneDays] = useState([]);
  const [nintyOneDays, setNintyOne] = useState([]);
  const [oneTwentyDays, setOneTwentyDays] = useState([]);

  useEffect(() => {
    getArAgingGraphData();
  }, []);

  const getArAgingGraphData = async () => {
    try {
      const { data } = await getArAging({ limit: 10, offset: 0 }, user.token);
      const { records } = data.data;

      let openCustomerTotal = [];
      let openCustomerBill = [];
      let zeroDays = [];
      let thirtyOneDays = [];
      let sixtyOneDays = [];
      let nintyOneDays = [];
      let oneTwentyDays = [];
      records.forEach(e => {
        openCustomerTotal.push(parseInt(e.total));
        openCustomerBill.push(e.bill_to_name.split(" "));
        zeroDays.push(parseInt(e["0-30 days"]));
        thirtyOneDays.push(parseInt(e["31-60 days"]));
        sixtyOneDays.push(parseInt(e["61-90 days"]));
        nintyOneDays.push(parseInt(e["91-120 days"]));
        oneTwentyDays.push(parseInt(e["Over 120 days"]));
      });

      setOpenCustomerBill(openCustomerBill);
      setZeroDays(zeroDays);
      setThirtyOneDays(thirtyOneDays);
      setSixtyOneDays(sixtyOneDays);
      setNintyOne(nintyOneDays);
      setOneTwentyDays(oneTwentyDays);
    } catch (error) { handleAxiosError(error, dispatch) }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [companyName, setCompanyName] = useState("");
  const onSearchSubmit = (e) => {
    e.preventDefault();
    setCompanyName(searchQuery);
  }
  const onSearchChange = (e) => {
    setSearchQuery(e.target.value.toUpperCase());
    if(!e.target.value) setCompanyName("");
  }

  const series = [
    {
      name: "0-30 days",
      data: zeroDays,
    },
    {
      name: "31-60 days",
      data: thirtyOneDays,
    },
    {
      name: "61-90 days",
      data: sixtyOneDays,
    },
    {
      name: "91-120 days",
      data: nintyOneDays,
    },
    {
      name: "over 120 days",
      data: oneTwentyDays,
    },
  ];

  const chartOptions = {
    // title: {
    //   text: "Top 10 Customers by Open Balance",
    // },
    chart: {
      type: "bar",
      height: 450,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: toUSD
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      title: {
        text: "Customer Name",
      },
      categories: openCustomerBill,
      labels: {
        show: true,
        rotate: 0,
        // rotateAlways: true,
        minHeight: 70,
        maxHeight: 110,
      },
    },
    yaxis: {
      title: {
        text: "$ (thousands)",
      },
      labels: {
        formatter: toUSCompact
      }
    },
    fill: {
      opacity: 1,
    }
  };


  return (
    <div>
      <Box sx={{ flexGrow: 1, p: 5 }}>
        <Paper className="paper-box-shadow" sx={{ p: "1rem" }}>
          <Typography variant="h6" className="mui-heading">
            Top 10 Customers by Open Balance
          </Typography>
          <Chart
            className="araging-bar-chart"
            options={chartOptions}
            series={series}
            type="bar"
            height={350}
          />
          <Box display='flex' pt={4} justifyContent="space-between">
            <Typography fontWeight={600} variant="h5">AR Aging Summary</Typography>
            <Box component='form' onSubmit={onSearchSubmit} width={300}>
              <TextField type='search' fullWidth size="small" label="Search Customer" 
                value={searchQuery} onChange={onSearchChange} />
            </Box>
          </Box>
          <ArAgingTable columns={tableDef} perPage={10} token={user.token} customerName={companyName} />
        </Paper>
      </Box>
    </div>
  );
}
