import axios from "axios";
import { baseUrl } from "../config/api-config";
// import { buildQuery } from "../config/api-config";

export const getKpiGraph = (token) => {
  //   let qs = buildQuery(query);
  let url = `${baseUrl}/kpi`;
  return axios.get(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};
